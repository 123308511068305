import { Box, Button, CircularProgress, Modal, TextField } from "@mui/material";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import SaveIcon from "@mui/icons-material/Save";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import * as React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  createNormative,
  deleteNormative,
  getNormatives,
  updateNormative,
} from "../../../redux/slices/normativeSlice";
import { showMessage } from "../../../redux/slices/messageSlice";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 4,
};

const NormativaListaAdministradorElement = ({
  id = "",
  isNew = false,
  setIsNew = null,
}) => {
  const dispatch = useDispatch();
  const normatives = useSelector(({ normative }) => normative.normatives);
  const normative = normatives?.find((item) => item._id == id);

  const [loading, setLoading] = React.useState(false);
  const [canEdit, setCanEdit] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const defaultValues = {
    name: "",
    act: "",
    url: "",
  };

  const schema = yup.object().shape({
    name: yup.string().required("Debe ingresar un nombre"),
    act: yup.string(),
    url: yup.string().required("Debe ingresar la URL"),
  });

  const {
    control,
    formState,
    handleSubmit,
    setError,
    setValue,
    getValues,
    watch,
  } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;

  function onSubmit() {
    if (id) {
      dispatch(updateNormative({ id: id, data: getValues() }))
        .then(() => {
          dispatch(
            showMessage({
              message: "Se ha guardado con éxito",
              autoHideDuration: 4000,
              severety: "success",
            })
          );
          dispatch(getNormatives());
          setCanEdit(false);
        })
        .catch((error) =>
          dispatch(
            showMessage({
              message: error.message,
              autoHideDuration: 4000,
              severety: "error",
            })
          )
        );
    } else {
      dispatch(createNormative(getValues()))
        .then(() => {
          dispatch(
            showMessage({
              message: "Elemento registrado con éxito",
              autoHideDuration: 4000,
              severety: "success",
            })
          );
          dispatch(getNormatives());
          setIsNew(false);
        })
        .catch((error) =>
          dispatch(
            showMessage({
              message: error.message,
              autoHideDuration: 4000,
              severety: "error",
            })
          )
        );
    }
  }

  const setKeysValues = () => {
    Object.keys(defaultValues).forEach((key) => {
      if (normative.hasOwnProperty(key)) {
        setValue(key, normative[key]);
      }
    });
  };

  React.useEffect(() => {
    if (id) setKeysValues();
  }, [id]);

  return (
    <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
      <TableCell>
        <div>
          {canEdit || isNew ? (
            <div className="flex gap-8">
              <Button
                variant="contained"
                color="secondary"
                className=" w-[140px] rounded-lg font-bold"
                disabled={!isValid || loading}
                onClick={onSubmit}
                size="large"
                startIcon={<SaveIcon />}
              >
                {loading ? (
                  <CircularProgress size={27} />
                ) : (
                  <p className="font-bold">Guardar</p>
                )}
              </Button>
              <Button
                variant="contained"
                color="secondary"
                className=" w-[140px] rounded-lg font-bold"
                disabled={loading}
                onClick={() => {
                  if (id) {
                    setKeysValues();
                    setCanEdit(false);
                  } else setIsNew(false);
                }}
                size="large"
              >
                {loading ? (
                  <CircularProgress size={27} />
                ) : (
                  <p className="font-bold">Cancelar</p>
                )}
              </Button>
            </div>
          ) : (
            <div className="flex gap-8">
              <Button
                variant="contained"
                color="secondary"
                className=" w-[140px] rounded-lg font-bold"
                onClick={() => setCanEdit(true)}
                size="large"
                startIcon={<BorderColorIcon />}
              >
                <p className="font-bold">Editar</p>
              </Button>
              <Button
                variant="contained"
                color="secondary"
                className=" w-[140px] rounded-lg font-bold"
                onClick={() => setOpenDelete(true)}
                size="large"
                startIcon={<DeleteIcon />}
              >
                <p className="font-bold">Eliminar</p>
              </Button>
            </div>
          )}
        </div>
      </TableCell>
      <TableCell>
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              disabled={!(canEdit || isNew)}
              className="mb-24"
              label="Nombre"
              error={!!errors.name}
              helperText={errors?.name?.message}
              variant="outlined"
              required
            />
          )}
        />
      </TableCell>
      <TableCell>
        <Controller
          name="act"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              disabled={!(canEdit || isNew)}
              className="mb-24"
              label="Acto resolutivo"
              error={!!errors.act}
              helperText={errors?.act?.message}
              variant="outlined"
            />
          )}
        />
      </TableCell>
      <TableCell>
        <Controller
          name="url"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              disabled={!(canEdit || isNew)}
              className="mb-24"
              label="Url del documento"
              error={!!errors.url}
              helperText={errors?.url?.message}
              variant="outlined"
              required
            />
          )}
        />
      </TableCell>

      {openDelete && (
        <Modal open={openDelete} onClose={handleCloseDelete}>
          <Box sx={style}>
            ¿Seguro que desea borrar este elemento? Este proceso es
            irreversible.
            <div className="flex justify-end mt-4 gap-6">
              <Button
                variant="contained"
                color="error"
                onClick={() => {
                  dispatch(deleteNormative(id))
                    .then((response) => {
                      dispatch(
                        showMessage({
                          message: "El elemento ha sido eliminado con exito",
                          autoHideDuration: 4000,
                          severety: "success",
                        })
                      );
                      dispatch(getNormatives());
                      setOpenDelete(false);
                    })
                    .catch(() => {
                      dispatch(
                        showMessage({
                          message: "Ha ocurrido un error",
                          autoHideDuration: 4000,
                          severety: "error",
                        })
                      );
                    });
                }}
              >
                Eliminar
              </Button>
              <Button
                variant="outlined"
                color="error"
                onClick={() => {
                  setOpenDelete(false);
                }}
              >
                Cancelar
              </Button>
            </div>
          </Box>
        </Modal>
      )}
    </TableRow>
  );
};

export default NormativaListaAdministradorElement;
