import ImageTitle from "../shared-components/ImageTitle";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { getConventions } from "../../redux/slices/conventionSlice";
import FilePresentIcon from "@mui/icons-material/FilePresent";

const ConvenioLista = () => {
  const dispatch = useDispatch();

  const conventions = useSelector(({ convention }) => convention.conventions);

  useEffect(() => {
    dispatch(getConventions());
  }, [dispatch]);

  return (
    <div>
      <ImageTitle title="CONVENIOS" img="assets/images/normativas.jpg" />
      <div className="p-8">
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            {conventions && conventions.length > 0 && (
              <TableHead>
                <TableRow>
                  <TableCell>
                    <p className="font-bold text-lg">Nombre del Convenio</p>
                  </TableCell>
                  <TableCell>
                    <p className="font-bold text-lg">Vigencia</p>
                  </TableCell>
                  <TableCell>
                    <p className="font-bold text-lg">Suscriptores</p>
                  </TableCell>
                  <TableCell>
                    <p className="font-bold text-lg">Ver documento</p>
                  </TableCell>
                </TableRow>
              </TableHead>
            )}
            <TableBody>
              {conventions &&
                conventions.length > 0 &&
                conventions?.map((item, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>{item.name}</TableCell>
                    <TableCell>{item.validity}</TableCell>
                    <TableCell>{item.subscribers}</TableCell>
                    <TableCell>
                      <FilePresentIcon
                        className="hover:cursor-pointer"
                        onClick={() => window.open(item.url, "_blank")}
                      />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        {conventions?.length == 0 && (
          <div className="flex flex-col items-center mt-12">
            <div className="flex flex-col gap-4 justify-center px-12">
              <p className="text-xl">
                En proceso de análisis y evaluación el Proyecto de Convenio de
                Cooperación Interinstitucional entre la Sociedad Peruana de
                Administración de Salud (SPEAS) y la Asociación Peruana de
                Entidades Prestadoras de Salud (APEPS).
              </p>
              <p className="text-xl">
                En etapa final de revisión el proyecto de Convenio Marco de
                Cooperación de Acciones Científicas entre la Sociedad Peruana de
                Administración de Salud y la Universidad Peruana Cayetano
                Heredia.
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ConvenioLista;
