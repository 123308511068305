const ImageTitle = ({ img, title, height = "" }) => {
  return (
    <div
      className={
        height
          ? `h-[${(height * 2) / 3}px] md:h-[${height}px]`
          : `h-[200px] md:h-[300px]`
      }
      style={{
        backgroundImage: `url(${img})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        backgroundSize: "cover",
      }}
    >
      <div
        style={{
          backgroundColor: "rgb(0,0,0,0.7)",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <span className="flex flex-col items-center">
          <span className="text-speasColors-primary text-2xl md:text-4xl font-extrabold mb-2 text-center">
            {title}
          </span>
          <div className="w-3/5 h-2 bg-white" />
        </span>
      </div>
    </div>
  );
};

export default ImageTitle;
