import { Button } from "@mui/material";

const ButtonSpeas = ({ icon, children, onClick, size = "large" }) => {
  return (
    <Button
      variant="contained"
      style={{ background: "#F5AD00" }}
      startIcon={icon}
      onClick={onClick}
      size={size}
    >
      <p className="font-bold text-xs md:text-lg">{children}</p>
    </Button>
  );
};

export default ButtonSpeas;
