import { useDispatch, useSelector } from "react-redux";
import ButtonSpeas from "../shared-components/ButtonSpeas";
import DividerSpeas from "../shared-components/DividerSpeas";
import ImageTitleHome from "../shared-components/ImageTitleHome";
import Title from "../shared-components/Title";
import SwiperStaff from "./components/SwiperStaff";
import Typography from "@mui/material/Typography";
import { useEffect } from "react";
import { getPhotosDirectors } from "../../redux/slices/photoDirectorSlice";
import { getFulltDirector } from "../../redux/slices/directorSlice";

const NosotrosPage = () => {
  const dispatch = useDispatch();

  const photosDirector = useSelector(
    ({ photosDirector }) => photosDirector.photosDirectors
  );

  const staff = useSelector(({ director }) => director.directorFull);

  useEffect(() => {
    dispatch(getPhotosDirectors());
    dispatch(getFulltDirector());
  }, [dispatch]);

  return (
    <>
      <ImageTitleHome
        title="SOCIEDAD PERUANA DE ADMINISTRACIÓN DE SALUD"
        img="assets/images/inicio.jpg"
        height="600px"
      />
      <div className="p-12 flex items-center justify-center flex-wrap">
        <div>
          <Typography className="max-w-[600px] text-justify" variant="h6">
            Somos la asociación de profesionales médicos cirujanos con la
            especialidad de Administración y Gerencia de Salud, comprometidos en
            contribuir a mejorar situación sanitaria del país y aportar en la
            formulación e implementación de las políticas de salud, a fortalecer
            la gestión de la prestación en servicios de salud, a promover la
            educación médica continua en el ámbito de la especialidad en
            concordancia con el perfil ocupacional requerido, así como promover
            y desarrollar la investigación en salud en el ámbito de la
            especialidad, entre otros aspectos.
          </Typography>
          <div className="flex justify-center w-full mt-8 gap-4">
            <ButtonSpeas
              onClick={() =>
                window.open("/assets/files/Historia_SPEAS.pdf", "_blank")
              }
            >
              Historia de SPEAS
            </ButtonSpeas>
            {/* <ButtonSpeas>Historial de Acciones</ButtonSpeas> */}
          </div>
        </div>
        <img src="/assets/images/logo.png" alt="logo de SPEAS" />
      </div>

      {staff && (
        <div className="p-12 flex flex-col items-center">
          <Title title="Consejo Directivo" />
          <SwiperStaff data={staff} />
          {photosDirector &&
            photosDirector.length > 0 &&
            photosDirector.map((item, index) => (
              <img
                key={index}
                className="w-[100%] md:w-[80%] my-[20px]"
                src={item.photo}
                alt="evento"
              />
            ))}
        </div>
      )}
    </>
  );
};

export default NosotrosPage;
