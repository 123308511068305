import ImageTitle from "../shared-components/ImageTitle";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getProjects } from "../../redux/slices/projectSlice";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Button } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ProyectoListaAdministradorElement from "./components/ProyectoListaAdministradorElement";

const ProyectoListaAdministrador = () => {
  const dispatch = useDispatch();
  const projects = useSelector(({ project }) => project.projects);
  const [newProject, setNewProject] = useState(false);

  useEffect(() => {
    dispatch(getProjects());
  }, [dispatch]);
  return (
    <div>
      <ImageTitle
        title="ADMINISTRADOR DE PLANES Y PROYECTOS"
        img="assets/images/normativas.jpg"
      />
      <div className="p-8 flex justify-end">
        <Button
          variant="contained"
          color="secondary"
          className=" w-[320px] mt-16 rounded-lg font-bold"
          disabled={newProject}
          onClick={() => {
            setNewProject(true);
          }}
          size="large"
          startIcon={<AddCircleOutlineIcon />}
        >
          <p className="font-bold">Registrar nuevo elemento</p>
        </Button>
      </div>
      <div className="px-8">
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>
                  <p className="font-bold text-lg">Nombre</p>
                </TableCell>
                <TableCell>
                  <p className="font-bold text-lg">Link del documento</p>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {newProject && (
                <ProyectoListaAdministradorElement
                  isNew={newProject}
                  setIsNew={setNewProject}
                />
              )}
              {projects &&
                projects.length > 0 &&
                projects?.map((item, index) => (
                  <ProyectoListaAdministradorElement
                    key={index}
                    id={item._id}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        {projects?.length == 0 && (
          <div className="flex flex-col items-center mt-12">
            <EventBusyIcon fontSize="inherit" style={{ fontSize: "100px" }} />
            <p className="font-extrabold text-4xl">
              No hay planes ni proyectos registrados
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProyectoListaAdministrador;
