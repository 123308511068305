import * as React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import _ from "lodash";
import Button from "@mui/material/Button";
import { TextField, CircularProgress } from "@mui/material";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { updatePassword } from "../../../redux/slices/userSlice";
import { showMessage } from "../../../redux/slices/messageSlice";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 4,
};

export default function ChangePasswordModal({ id }) {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    reset();
    setOpen(false);
  };

  const schema = yup.object().shape({
    password: yup.string().required("Debe ingresar su contraseña actual"),
    newPassword: yup
      .string()
      .required("Debe ingresar su nueva contraseña")
      .min(12, "La contraseña debe tener al menos 12 caracteres")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).*$/,
        "La contraseña debe contener al menos una letra mayúscula, una letra minúscula y un número"
      ),
    confirmationPassword: yup
      .string()
      .required("Debe ingresar su nueva contraseña")
      .oneOf([yup.ref("newPassword")], "Las contraseñas no coinciden"),
  });

  const defaultValues = {
    password: "",
    newPassword: "",
    confirmationPassword: "",
  };

  const {
    control,
    formState,
    handleSubmit,
    getValues,
    reset,
    setError,
    setValue,
  } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;

  function handleChangePassword() {
    setLoading(true);
    const data = getValues();
    dispatch(updatePassword({ id, data }))
      .unwrap()
      .then((response) => {
        dispatch(
          showMessage({
            message: "Contraseña cambiada con éxito",
            autoHideDuration: 4000,
            severety: "success",
          })
        );
        handleClose();
      })
      .catch((error) => {
        dispatch(
          showMessage({
            message: error.message,
            autoHideDuration: 4000,
            severety: "error",
          })
        );
      })
      .finally(() => setLoading(false));
  }

  return (
    <div>
      <Button
        variant="outlined"
        color="primary"
        size="large"
        className=" w-full mt-16 rounded-lg font-bold"
        onClick={handleOpen}
      >
        <p className="font-bold">Cambiar contraseña</p>
      </Button>
      {open && (
        <Modal open={open} onClose={handleClose}>
          <Box sx={style}>
            <div className="flex flex-col items-center w-full max-w-320 sm:w-320 mx-auto sm:mx-0">
              <div className="flex flex-col justify-center w-full">
                <p className="text-xl mt-4">Contraseña actual</p>
                <Controller
                  name="password"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="mb-24"
                      autoFocus
                      error={!!errors.password}
                      helperText={errors?.password?.message}
                      variant="outlined"
                      type="password"
                      required
                      fullWidth
                    />
                  )}
                />
                <p className="text-xl mt-4">Nueva contraseña</p>
                <Controller
                  name="newPassword"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="mb-24"
                      type="password"
                      error={!!errors.newPassword}
                      helperText={errors?.newPassword?.message}
                      variant="outlined"
                      required
                      fullWidth
                    />
                  )}
                />

                <p className="text-xl mt-4">Confirmar contraseña</p>
                <Controller
                  name="confirmationPassword"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="mb-24"
                      type="password"
                      error={!!errors.confirmationPassword}
                      helperText={errors?.confirmationPassword?.message}
                      variant="outlined"
                      required
                      fullWidth
                    />
                  )}
                />
                <div className="mt-8">
                  <Button
                    variant="contained"
                    color="secondary"
                    className=" w-full rounded-lg font-bold"
                    disabled={_.isEmpty(dirtyFields) || !isValid || loading}
                    onClick={() => handleChangePassword()}
                    size="large"
                  >
                    {loading ? (
                      <CircularProgress size={27} />
                    ) : (
                      <p className="font-bold">Reestablecer contraseña</p>
                    )}
                  </Button>
                </div>
              </div>
            </div>
          </Box>
        </Modal>
      )}
    </div>
  );
}
