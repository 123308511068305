import { Grid } from "swiper";
import ImageTitle from "../shared-components/ImageTitle";
import ActividadElement from "./components/ActividadElement";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getActivities } from "../../redux/slices/activitySlice";
import AdministrarActividadElement from "./components/AdministrarActividadElement";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Button, Divider, Tab, Tabs } from "@mui/material";
import { getCurrentModal } from "../../redux/slices/modalSlice";

const AdministrarActividadesPage = () => {
  const dispatch = useDispatch();
  const activities = useSelector(({ activity }) => activity.activities);

  const [activityState, setActivityState] = useState("pending");
  const [newActivity, setNewActivity] = useState(false);

  useEffect(() => {
    dispatch(getActivities());
    dispatch(getCurrentModal());
  }, [dispatch]);

  return (
    <div>
      <ImageTitle
        title="ADMINISTRADOR DE ACTIVIDADES"
        img="assets/images/actividades.jpg"
      />
      <div className="flex justify-around ">
        <div
          onClick={() => setActivityState("pending")}
          className={`p-3 w-full flex flex-col items-center hover:bg-gray-100 hover:cursor-pointer`}
        >
          <span
            className={`flex gap-2 p-2 items-center ${
              activityState == "pending" &&
              "border-b-[6px] border-speasColors-primary"
            }`}
          >
            <CalendarMonthIcon fontSize="large" />
            <p className={`text-xl md:text-2xl font-medium`}>PROGRAMADAS</p>
          </span>
        </div>
        <div
          onClick={() => setActivityState("executed")}
          className={`p-3 w-full flex flex-col items-center hover:bg-gray-100 hover:cursor-pointer`}
        >
          <span
            className={`flex gap-2 p-2 items-center ${
              activityState == "executed" &&
              "border-b-[6px] border-speasColors-primary"
            }`}
          >
            <EventAvailableIcon fontSize="large" />
            <p className={`text-xl md:text-2xl font-medium`}>REALIZADAS</p>
          </span>
        </div>
      </div>
      <div className="p-8 flex justify-end">
        <Button
          variant="contained"
          color="secondary"
          className=" w-[320px] mt-16 rounded-lg font-bold"
          disabled={newActivity}
          onClick={() => {
            setNewActivity(true);
          }}
          size="large"
          startIcon={<AddCircleOutlineIcon />}
        >
          <p className="font-bold">Registrar nueva actividad</p>
        </Button>
      </div>
      {newActivity && (
        <div>
          <AdministrarActividadElement isNew={true} setIsNew={setNewActivity} />
          <Divider />
        </div>
      )}
      {activities &&
      activities.length > 0 &&
      activities?.filter((item) => item.state == activityState).length > 0 ? (
        activities
          ?.filter((item) => item.state == activityState)
          ?.map((item, index) => (
            <div key={index}>
              <AdministrarActividadElement id={item._id} />
              <Divider />
            </div>
          ))
      ) : (
        <div className="flex flex-col items-center mt-12">
          <EventBusyIcon fontSize="inherit" style={{ fontSize: "100px" }} />
          <p className="font-extrabold text-4xl">
            No hay actividades registradas
          </p>
        </div>
      )}
    </div>
  );
};

export default AdministrarActividadesPage;
