const SwiperCard = ({ item, position }) => {
  return (
    <div className="flex flex-col justify-center items-center mb-12">
      <div className="h-12 flex items-center pb-8 pt-12">
        <span className="text-center font-bold">{position}</span>
      </div>

      <div
        style={{
          height: "200px",
          width: "200px",
          backgroundImage: `url(${
            item?.photo ? item?.photo : "/assets/images/user.jpg"
          })`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          backgroundSize: "cover",
        }}
      />
      <div className="h-12 flex items-center py-4">
        <span className="text-center">
          {item?.name} {item?.lastname}
        </span>
      </div>
    </div>
  );
};

export default SwiperCard;
