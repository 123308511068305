import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchWithToken, fetchWithoutToken } from "../helpers/fetch";

const initialState = {
  photosDirectors: [],
  photosDirector: {},
};

export const createPhotosDirector = createAsyncThunk(
  "photos-director/create",
  async (data) => {
    let photosDirector;

    try {
      const response = await fetchWithToken({
        endpoint: `/photos-director/register`,
        data: data,
        method: "POST",
      });
      photosDirector = response.data;
    } catch (error) {
      throw error;
    }

    return photosDirector;
  }
);

export const getPhotosDirectors = createAsyncThunk(
  "photos-directors/list",
  async () => {
    let list;
    try {
      const response = await fetchWithoutToken({
        endpoint: `/photos-directors`,
      });
      list = response.data;
    } catch (error) {
      list = [];
    }
    return list;
  }
);

export const getPhotosDirectorById = createAsyncThunk(
  "photos-director/id",
  async (id) => {
    let photosDirector = {};

    try {
      const response = await fetchWithoutToken(`/photos-director/${id}`);
      photosDirector = response.data;
    } catch (error) {
      photosDirector = {};
    }
    return photosDirector;
  }
);

export const updatePhotosDirector = createAsyncThunk(
  "photos-director/update",
  async ({ id, data }) => {
    let photosDirector = {};

    try {
      const response = await fetchWithToken({
        endpoint: `/photos-director/update/${id}`,
        data: data,
        method: "PUT",
      });
      photosDirector = response.data;
      return photosDirector;
    } catch (error) {
      throw error;
    }
  }
);

export const deletePhotosDirector = createAsyncThunk(
  "photos-director/delete",
  async (id) => {
    let photoDeleted;
    try {
      const response = await fetchWithToken({
        endpoint: `/photos-director/delete/${id}`,
        method: "DELETE",
      });
      photoDeleted = response.data;
    } catch (error) {
      photoDeleted = {};
    }
    return photoDeleted;
  }
);

const photosDirectorSlice = createSlice({
  initialState,
  name: "photosDirector",
  reducers: {},
  extraReducers: {
    [getPhotosDirectors.fulfilled]: (state, action) => {
      state.photosDirectors = action.payload;
    },
    [getPhotosDirectorById.fulfilled]: (state, action) => {
      state.photosDirector = action.payload;
    },
  },
});

export default photosDirectorSlice.reducer;
