import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchWithToken, fetchWithoutToken } from "../helpers/fetch";

const initialState = {
  conventions: [],
  convention: {},
};

export const createConvention = createAsyncThunk(
  "convention/create",
  async (data) => {
    let convention;

    try {
      const response = await fetchWithToken({
        endpoint: `/convention/register`,
        data: data,
        method: "POST",
      });
      convention = response.data;
    } catch (error) {
      throw error;
    }

    return convention;
  }
);

export const getConventions = createAsyncThunk(
  "conventions/list",
  async (filters) => {
    let list;

    const filter = JSON.stringify(filters);

    try {
      const response = await fetchWithoutToken({ endpoint: `/conventions` });
      list = response.data;
    } catch (error) {
      list = [];
    }
    return list;
  }
);

export const getConventionById = createAsyncThunk("convention", async (id) => {
  let convention = {};

  try {
    const response = await fetchWithoutToken(`/convention/${id}`);
    convention = response.data;
  } catch (error) {
    convention = {};
  }
  return convention;
});

export const updateConvention = createAsyncThunk(
  "convention/update",
  async ({ id, data }) => {
    let convention = {};

    try {
      const response = await fetchWithToken({
        endpoint: `/convention/update/${id}`,
        data: data,
        method: "PUT",
      });
      convention = response.data;
      return convention;
    } catch (error) {
      throw error;
    }
  }
);

export const deleteConvention = createAsyncThunk(
  "convention/delete",
  async (id) => {
    let conventionDeleted = {};

    try {
      const response = await fetchWithToken({
        endpoint: `/convention/delete/${id}`,
        method: "DELETE",
      });
      conventionDeleted = response.data;
    } catch (error) {
      conventionDeleted = {};
    }
    return conventionDeleted;
  }
);

const conventionSlice = createSlice({
  initialState,
  name: "convention",
  reducers: {},
  extraReducers: {
    [getConventions.fulfilled]: (state, action) => {
      state.conventions = action.payload;
    },
    [getConventionById.fulfilled]: (state, action) => {
      state.convention = action.payload;
    },
  },
});

export default conventionSlice.reducer;
