import { configureStore } from "@reduxjs/toolkit";
import activitySlice from "./slices/activitySlice";
import userSlice from "./slices/userSlice";
import authSlice from "./slices/authSlice";
import messageSlice from "./slices/messageSlice";
import photosDirectorSlice from "./slices/photoDirectorSlice";
import directorSlice from "./slices/directorSlice";
import investigationSlice from "./slices/investigationSlice";
import modalSlice from "./slices/modalSlice";
import conventionSlice from "./slices/conventionSlice";
import normativeSlice from "./slices/normativeSlice";
import projectSlice from "./slices/projectSlice";

export const store = configureStore({
  reducer: {
    activity: activitySlice,
    user: userSlice,
    auth: authSlice,
    message: messageSlice,
    photosDirector: photosDirectorSlice,
    director: directorSlice,
    investigation: investigationSlice,
    modal: modalSlice,
    convention: conventionSlice,
    normative: normativeSlice,
    project: projectSlice,
  },
});
