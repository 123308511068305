import axios from "axios";

const baseUrl = process.env.REACT_APP_API_URL;

export const fetchWithoutToken = ({ endpoint, data, method = "GET" }) => {
  const url = `${baseUrl}${endpoint}`;

  switch (method) {
    case "POST":
      return axios.post(url, data).catch((error) => {
        throw new Error(error.response.data.error);
      });

    case "PUT":
      return axios.put(url, data).catch((error) => {
        throw new Error(error.response.data.error);
      });

    case "DELETE":
      return axios.delete(url, data).catch((error) => {
        throw new Error(error.response.data.error);
      });

    case "PATCH":
      return axios.patch(url, data).catch((error) => {
        throw new Error(error.response.data.error);
      });

    case "GET":
      return axios.get(url).catch((error) => {
        throw new Error(error.response.data.error);
      });

    default:
      throw new Error("Debe enviar un metodo válido");
  }
};

export const fetchWithToken = ({
  endpoint,
  data,
  method = "GET",
  blob = false,
  content = false,
}) => {
  const url = `${baseUrl}${endpoint}`;
  const token = localStorage.getItem("jwt_access_token_speas") || "";
  const config = {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": !content ? "application/json" : content,
    },
    ...(blob ? { responseType: "blob" } : {}),
  };

  switch (method) {
    case "POST":
      return axios.post(url, data, config).catch((error) => {
        throw new Error(error.response.data.error);
      });

    case "PUT":
      return axios.put(url, data, config).catch((error) => {
        throw new Error(error.response.data.error);
      });

    case "DELETE":
      return axios.delete(url, config).catch((error) => {
        throw new Error(error.response.data.error);
      });

    case "PATCH":
      return axios.patch(url, data, config).catch((error) => {
        throw new Error(error.response.data.error);
      });

    case "GET":
      return axios.get(url, config).catch((error) => {
        throw new Error(error.response.data.error);
      });

    default:
      throw new Error("Debe enviar un metodo válido");
  }
};
