import ImageTitle from "../shared-components/ImageTitle";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getConventions } from "../../redux/slices/conventionSlice";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Button } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ConvenioListaAdministradorElement from "./components/ConvenioListaAdministradorElement";

const ConvenioListaAdministrador = () => {
  const dispatch = useDispatch();
  const conventions = useSelector(({ convention }) => convention.conventions);
  const [newConvention, setNewConvention] = useState(false);

  useEffect(() => {
    dispatch(getConventions());
  }, [dispatch]);
  return (
    <div>
      <ImageTitle
        title="ADMINISTRADOR DE CONVENIOS"
        img="assets/images/normativas.jpg"
      />
      <div className="p-8 flex justify-end">
        <Button
          variant="contained"
          color="secondary"
          className=" w-[320px] mt-16 rounded-lg font-bold"
          disabled={newConvention}
          onClick={() => {
            setNewConvention(true);
          }}
          size="large"
          startIcon={<AddCircleOutlineIcon />}
        >
          <p className="font-bold">Registrar nuevo Convenio</p>
        </Button>
      </div>
      <div className="px-8">
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>
                  <p className="font-bold text-lg">Nombre del Convenio</p>
                </TableCell>
                <TableCell>
                  <p className="font-bold text-lg">Vigencia</p>
                </TableCell>
                <TableCell>
                  <p className="font-bold text-lg">Suscriptores</p>
                </TableCell>
                <TableCell>
                  <p className="font-bold text-lg">Link del documento</p>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {newConvention && (
                <ConvenioListaAdministradorElement
                  isNew={newConvention}
                  setIsNew={setNewConvention}
                />
              )}
              {conventions &&
                conventions.length > 0 &&
                conventions?.map((item, index) => (
                  <ConvenioListaAdministradorElement
                    key={index}
                    id={item._id}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        {conventions?.length == 0 && (
          <div className="flex flex-col items-center mt-12">
            <EventBusyIcon fontSize="inherit" style={{ fontSize: "100px" }} />
            <p className="font-extrabold text-4xl">
              No hay convenios registrados
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ConvenioListaAdministrador;
