import ImageTitle from "../shared-components/ImageTitle";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { createUser, deleteUser, getUsers } from "../../redux/slices/userSlice";
import { IconButton } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControl from "@mui/material/FormControl";
import DeleteIcon from "@mui/icons-material/Delete";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import moment from "moment";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { showMessage } from "../../redux/slices/messageSlice";
import {
  genders,
  isAdmin,
  isFinance,
  isSecretary,
} from "../../helpers/helpers";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 4,
  overflow: "auto",
};

const UsuariosHome = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const users = useSelector(({ user }) => user.users);

  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseDelete = () => {
    setOpenDelete(false);
    setUserToDelete(null);
  };

  const schema = yup.object().shape({
    name: yup.string().required("Debe ingresar su(s) nombre(s)"),
    lastname: yup.string().required("Debe ingresar su(s) apellido(s)"),
    gender: yup.string().required("Debe seleccionar un género"),
    email: yup
      .string()
      .email("Debe ingresar un email válido")
      .required("Debe ingresar su email"),
    dni: yup
      .string()
      .matches(/^[0-9]+$/, "El DNI debe contener solo números")
      .required("Debe ingresar su DNI")
      .test("digits", "El DNI debe ser un número válido", function (value) {
        if (isNaN(value)) {
          return false;
        }
        const stringValue = String(value);
        return stringValue.length === 8;
      }),
    CMP: yup
      .string()
      .matches(/^[0-9]+$/, "El número de CMP debe contener solo números")
      .required("Debe ingresar su número de CMP"),
    RNE: yup
      .string()
      .matches(/^[0-9]+$/, "El número de RNE debe contener solo números")
      .required("Debe ingresar su número de RNE"),
    code: yup.string().required("Debe ingresar su número de registro"),
    date_admission: yup
      .string()
      .required("El campo fecha de admisión es requerido")
      .test("valid-date", "La fecha ingresada no es válida", function (value) {
        return moment(value, "DD-MM-YYYY", true).isValid();
      }),
    birthday: yup
      .string()
      .test("valid-date", "La fecha ingresada no es válida", function (value) {
        if (!value) return true;
        return moment(value, "DD-MM-YYYY", true).isValid();
      }),
    phone: yup
      .string()
      .test(
        "is-empty",
        "Debe ingresar un número de teléfono válido",
        function (value) {
          if (value) {
            return /^\+?[0-9]+$/.test(value);
          }
          return true;
        }
      ),
    address: yup.string(),
  });

  const defaultValues = {
    name: "",
    lastname: "",
    gender: "",
    email: "",
    dni: "",
    CMP: "",
    RNE: "",
    code: "",
    date_admission: "",
    birthday: "",
    phone: "",
    address: "",
    payments: [],
  };

  const {
    control,
    formState,
    handleSubmit,
    setError,
    setValue,
    getValues,
    watch,
    reset,
  } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;

  useEffect(() => {
    dispatch(getUsers());
  }, []);

  return (
    <div>
      <ImageTitle
        title="ADMINISTRADOR DE USUARIOS"
        img="assets/images/usuarios.jpg"
      />
      <div className="p-12 flex flex-col items-center">
        {(isAdmin() || isFinance() || isSecretary()) && (
          <div className="flex w-full justify-end mb-4">
            <Button
              variant="contained"
              color="secondary"
              className=" w-[300px] mt-16 rounded-lg font-bold"
              onClick={() => {
                setOpen(true);
              }}
              size="large"
              startIcon={<AddCircleOutlineIcon />}
            >
              <p className="font-bold">Registrar nuevo usuario</p>
            </Button>
          </div>
        )}
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 400 }}>
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>Nombre(s)</TableCell>
                <TableCell>Apellido(s)</TableCell>
                <TableCell>Correo</TableCell>
                <TableCell>Número de Registro</TableCell>
                <TableCell>DNI</TableCell>
                <TableCell>CMP</TableCell>
                <TableCell>RNE</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users &&
                users.length > 0 &&
                users.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <IconButton
                        onClick={() => navigate(`/usuario/${row._id}`)}
                        color="primary"
                      >
                        <BorderColorIcon fontSize="inherit" />
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          setUserToDelete(row._id);
                          setOpenDelete(true);
                        }}
                        color="warning"
                      >
                        <DeleteIcon fontSize="inherit" />
                      </IconButton>
                    </TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.lastname}</TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell>{row.code}</TableCell>
                    <TableCell>{row.dni}</TableCell>
                    <TableCell>{row.CMP}</TableCell>
                    <TableCell>{row.RNE}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {open && (
        <Modal open={open} onClose={handleClose}>
          <Box sx={style}>
            <div className="flex flex-col items-center w-full mx-auto sm:mx-0 gap-4 h-[80vh]">
              <div className="flex flex-col gap-4 w-full">
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="mb-24"
                      label="Nombre(s)"
                      error={!!errors.name}
                      helperText={errors?.name?.message}
                      variant="outlined"
                      required
                    />
                  )}
                />
                <Controller
                  name="lastname"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="mb-24"
                      label="Apellido(s)"
                      error={!!errors.lastname}
                      helperText={errors?.lastname?.message}
                      variant="outlined"
                      required
                    />
                  )}
                />
                <Controller
                  name="gender"
                  control={control}
                  render={({ field }) => (
                    <FormControl>
                      <InputLabel>Género *</InputLabel>
                      <Select
                        label="Género *"
                        {...field}
                        defaultValue=""
                        className="w-full"
                      >
                        {genders?.map((item, index) => (
                          <MenuItem key={index} value={item.value}>
                            {item.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />

                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="mb-24"
                      label="Email"
                      error={!!errors.email}
                      helperText={errors?.email?.message}
                      variant="outlined"
                      required
                    />
                  )}
                />
                <Controller
                  name="dni"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="mb-24"
                      label="Número de DNI"
                      error={!!errors.dni}
                      helperText={errors?.dni?.message}
                      variant="outlined"
                      required
                    />
                  )}
                />
                <Controller
                  name="CMP"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="mb-24"
                      label="Número de CMP"
                      error={!!errors.CMP}
                      helperText={errors?.CMP?.message}
                      variant="outlined"
                      required
                    />
                  )}
                />
                <Controller
                  name="RNE"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="mb-24"
                      label="Número de RNE"
                      error={!!errors.RNE}
                      helperText={errors?.RNE?.message}
                      variant="outlined"
                      required
                    />
                  )}
                />
                <Controller
                  name="code"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="mb-24"
                      label="Número de Registro"
                      error={!!errors.code}
                      helperText={errors?.code?.message}
                      variant="outlined"
                      required
                    />
                  )}
                />
                <Controller
                  name="date_admission"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="mb-24"
                      label="Fecha de Ingreso"
                      error={!!errors.date_admission}
                      helperText={errors?.date_admission?.message}
                      variant="outlined"
                      placeholder="DD-MM-YYYY"
                      required
                    />
                  )}
                />
                <Controller
                  name="birthday"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="mb-24"
                      label="Fecha de Nacimiento"
                      error={!!errors.birthday}
                      helperText={errors?.birthday?.message}
                      variant="outlined"
                      placeholder="DD-MM-YYYY"
                    />
                  )}
                />
                <Controller
                  name="phone"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="mb-24"
                      label="Número de Celular"
                      error={!!errors.phone}
                      helperText={errors?.phone?.message}
                      variant="outlined"
                    />
                  )}
                />
                <Controller
                  name="address"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="mb-24"
                      label="Dirección"
                      multiline
                      error={!!errors.address}
                      helperText={errors?.address?.message}
                      variant="outlined"
                    />
                  )}
                />
              </div>
              <div className="flex gap-12 pb-4">
                <Button
                  variant="contained"
                  className=" w-full mt-16 rounded-lg font-bold"
                  size="large"
                  disabled={!isValid}
                  onClick={async () => {
                    try {
                      const response = await dispatch(
                        createUser(getValues())
                      ).unwrap();
                      dispatch(
                        showMessage({
                          message: "Se ha guardado el usuario con éxito.",
                          autoHideDuration: 4000,
                          severety: "success",
                        })
                      );
                      dispatch(getUsers());
                      reset();
                      handleClose();
                    } catch (error) {
                      dispatch(
                        showMessage({
                          message: error.message,
                          autoHideDuration: 4000,
                          severety: "error",
                        })
                      );
                    }
                  }}
                >
                  <p className="font-bold">Guardar</p>
                </Button>
                <Button
                  variant="outlined"
                  className=" w-full mt-16 rounded-lg font-bold"
                  size="large"
                  onClick={handleClose}
                >
                  <p className="font-bold">Cancelar</p>
                </Button>
              </div>
            </div>
          </Box>
        </Modal>
      )}
      {openDelete && (
        <Modal open={openDelete} onClose={handleCloseDelete}>
          <Box sx={style}>
            ¿Seguro que desea borrar este usuario? Este proceso es irreversible.
            <div className="flex justify-end mt-4">
              <Button
                variant="contained"
                color="error"
                onClick={() => {
                  dispatch(deleteUser(userToDelete)).then((response) => {
                    dispatch(
                      showMessage({
                        message: "El usuario ha sido eliminado con exito",
                        autoHideDuration: 4000,
                        severety: "success",
                      })
                    );
                    dispatch(getUsers());
                  });
                  setOpenDelete(false);
                }}
              >
                Borrar usuario
              </Button>
            </div>
          </Box>
        </Modal>
      )}
    </div>
  );
};

export default UsuariosHome;
