import SwiperNews from "./components/SwiperNews";

const data = [
  {
    img: "https://thumbs.dreamstime.com/b/paisajes-de-yosemite-46208063.jpg",
    title: "CURSO MODULAR ONLINE 1",
    subtitle: "GERENCIA DE SERVICIOS DE SALUD 1",
  },
  {
    img: "https://img.freepik.com/foto-gratis/humedo-paisajes-escenicos-paisaje-limpio-flujo_1417-1106.jpg?w=2000",
    title: "CURSO MODULAR ONLINE 2",
    subtitle: "GERENCIA DE SERVICIOS DE SALUD 2",
  },
  {
    img: "https://thumbs.dreamstime.com/b/paisajes-de-yosemite-46208063.jpg",
    title: "CURSO MODULAR ONLINE 3",
    subtitle: "GERENCIA DE SERVICIOS DE SALUD 3",
  },
  {
    img: "https://img.freepik.com/foto-gratis/humedo-paisajes-escenicos-paisaje-limpio-flujo_1417-1106.jpg?w=2000",
    title: "CURSO MODULAR ONLINE 4",
    subtitle: "GERENCIA DE SERVICIOS DE SALUD 4",
  },
];

const Home = () => {
  return (
    <div className="p-12">
      <SwiperNews data={data} />
    </div>
  );
};

export default Home;
