import {
  BrowserRouter,
  Route,
  Routes,
  Redirect,
  Navigate,
  useNavigate,
} from "react-router-dom";
import "./App.css";
import AppBarComponent from "./components/AppBar";
import Footer from "./components/Footer";
import ActividadesPage from "./pages/Actividades/Actividades";
import AsociarsePage from "./pages/Asociarse/Asociarse";
import Home from "./pages/Home/Home";
import NormativasPage from "./pages/Normativas/Normativas";
import NosotrosPage from "./pages/Nosotros/Nosotros";
import InvestigacionPage from "./pages/Investigacion/Investigacion";
import UsuariosHome from "./pages/Usuarios/Usuarios";
import UsuarioHome from "./pages/Usuarios/Usuario";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Notification from "./pages/shared-components/Notification";
import { useSelector } from "react-redux";
import { useState } from "react";
import { PrivateRoute, USER_STATUS } from "./helpers/helpers";
import Error404Page from "./pages/404/Error404";
import ConsejoDirectivoHome from "./pages/ConsejoDirectivo/ConsejoDIrectivo";
import AdministrarActividadesPage from "./pages/Actividades/AdministrarActividades";
import AdministrarInvestigacionPage from "./pages/Investigacion/AdministrarInvestigacion";
import ActivityModal from "./pages/shared-components/ActivityModal";
import ConvenioLista from "./pages/ConvenioLista/ConvenioLista";
import ConvenioListaAdministrador from "./pages/ConvenioLista/ConvenioListaAdministrador";
import NormativaLista from "./pages/NormativaLista/NormativaLista";
import NormativaListaAdministrador from "./pages/NormativaLista/NormativaListaAdministrador";
import ProyectoLista from "./pages/ProyectoLista/ProyectoLista";
import ProyectoListaAdministrador from "./pages/ProyectoLista/ProyectoListaAdministrador";
function App() {
  const [notification, setNotification] = useState({
    message: "hola",
    severity: "success",
  });
  return (
    <BrowserRouter>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div className="flex flex-col min-h-screen">
          <AppBarComponent />
          <div className="flex-grow">
            <Routes>
              <Route path="/" element={<NosotrosPage />} />
              <Route path="/normativas" element={<NormativasPage />} />
              <Route path="/asociarse" element={<AsociarsePage />} />
              <Route path="/actividades" element={<ActividadesPage />} />
              <Route path="/investigacion" element={<InvestigacionPage />} />
              <Route path="/convenios" element={<ConvenioLista />} />
              <Route
                path="/normativas-y-herramientas"
                element={<NormativaLista />}
              />
              <Route path="/planes-y-proyectos" element={<ProyectoLista />} />
              <Route
                path="/consejo-directivo"
                element={
                  <PrivateRoute
                    roles={[USER_STATUS.ADMIN, USER_STATUS.SECRETARY]}
                  >
                    <ConsejoDirectivoHome />
                  </PrivateRoute>
                }
              />
              <Route
                path="/administrar-actividades"
                element={
                  <PrivateRoute
                    roles={[USER_STATUS.ADMIN, USER_STATUS.SECRETARY]}
                  >
                    <AdministrarActividadesPage />
                  </PrivateRoute>
                }
              />
              <Route
                path="/administrar-investigacion"
                element={
                  <PrivateRoute
                    roles={[USER_STATUS.ADMIN, USER_STATUS.INVESTIGATION]}
                  >
                    <AdministrarInvestigacionPage />
                  </PrivateRoute>
                }
              />
              <Route
                path="/administrar-usuarios"
                element={
                  <PrivateRoute
                    roles={[
                      USER_STATUS.ADMIN,
                      USER_STATUS.FINANCE,
                      USER_STATUS.SECRETARY,
                    ]}
                  >
                    <UsuariosHome />
                  </PrivateRoute>
                }
              />
              <Route
                path="/usuario/:id"
                element={
                  <PrivateRoute
                    roles={[
                      USER_STATUS.ADMIN,
                      USER_STATUS.FINANCE,
                      USER_STATUS.SECRETARY,
                    ]}
                  >
                    <UsuarioHome />
                  </PrivateRoute>
                }
              />
              <Route
                path="/administrar-convenios"
                element={
                  <PrivateRoute
                    roles={[USER_STATUS.ADMIN, USER_STATUS.SECRETARY]}
                  >
                    <ConvenioListaAdministrador />
                  </PrivateRoute>
                }
              />
              <Route
                path="/administrar-normativas-y-herramientas"
                element={
                  <PrivateRoute
                    roles={[USER_STATUS.ADMIN, USER_STATUS.SECRETARY]}
                  >
                    <NormativaListaAdministrador />
                  </PrivateRoute>
                }
              />
              <Route
                path="/administrar-planes-y-proyectos"
                element={
                  <PrivateRoute
                    roles={[USER_STATUS.ADMIN, USER_STATUS.SECRETARY]}
                  >
                    <ProyectoListaAdministrador />
                  </PrivateRoute>
                }
              />
              <Route
                path="/mi-perfil"
                element={
                  <PrivateRoute
                    roles={[
                      USER_STATUS.USER,
                      USER_STATUS.FINANCE,
                      USER_STATUS.SECRETARY,
                      USER_STATUS.INVESTIGATION,
                    ]}
                  >
                    <UsuarioHome />
                  </PrivateRoute>
                }
              />
              <Route path="/*" element={<Error404Page />} />
            </Routes>
          </div>
          <Footer />
          <Notification
            open={notification.message !== ""}
            onClose={() => setNotification({ ...notification, message: "" })}
            message={notification.message}
          />
          <ActivityModal />
        </div>
      </LocalizationProvider>
    </BrowserRouter>
  );
}

export default App;
