import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import _ from "lodash";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { staffMembers } from "../../../helpers/helpers";
import { useEffect, useState } from "react";
import { getUsers } from "../../../redux/slices/userSlice";
import { Autocomplete, Button, TextField } from "@mui/material";
import {
  createDirector,
  getCurrentDirector,
  updateDirector,
} from "../../../redux/slices/directorSlice";
import { showMessage } from "../../../redux/slices/messageSlice";

const Miembros = () => {
  const dispatch = useDispatch();
  const users = useSelector(({ user }) => user.users);
  const director = useSelector(({ director }) => director.director);

  const [show, setShow] = useState(false);

  const memberSchema = yup.object().shape({
    position: yup.number().required(),
    id_member: yup.string().required("Seleccione un miembro"),
  });

  const defaultValuesMember = {
    position: 0,
    id_member: "",
  };

  const schema = yup.object().shape({
    year: yup.string().required("Debe ingresar el año del Consejo Directivo"),
    president: memberSchema,
    vice_president: memberSchema,
    general_secretary: memberSchema,
    finance_secretary: memberSchema,
    scientific_secretary: memberSchema,
    branch_secretary: memberSchema,
    ethics_member: memberSchema,
    research_member: memberSchema,
    past_president: memberSchema,
  });

  const defaultValues = {
    year: "",
    president: {},
    vice_president: {},
    general_secretary: {},
    finance_secretary: {},
    scientific_secretary: {},
    branch_secretary: {},
    ethics_member: {},
    research_member: {},
    past_president: {},
  };

  const memberForm = useForm({
    mode: "onChange",
    defaultValuesMember,
    resolver: yupResolver(memberSchema),
  });

  const {
    control,
    formState,
    handleSubmit,
    setError,
    setValue,
    getValues,
    watch,
  } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;

  const keys = Object.keys(schema.fields).filter((key) => key !== "year"); // Obtener las claves del esquema excluyendo "year"

  const membersKeys = keys.map((key, index) => ({
    id: index + 1,
    key: key,
    label: staffMembers[index],
  }));

  const handleUpdate = () => {
    dispatch(updateDirector({ id: director._id, data: getValues() }))
      .then(() => {
        dispatch(
          showMessage({
            message: "El Consejo Directivo ha sido actualizado con éxito",
            autoHideDuration: 4000,
            severety: "success",
          })
        );
      })
      .catch(() => {
        dispatch(
          showMessage({
            message: "Ha ocurrido un problema",
            autoHideDuration: 4000,
            severety: "error",
          })
        );
      });
  };

  useEffect(() => {
    dispatch(getUsers()).then(() => {
      dispatch(getCurrentDirector())
        .then(({ payload }) => {
          Object.keys(defaultValues).forEach((key) => {
            if (payload.hasOwnProperty(key)) {
              if (key !== "year") {
                setValue(`${key}.position`, payload[key].position);
                setValue(`${key}.gender`, payload[key].gender);
                setValue(`${key}.id_member`, payload[key].id_member);
              } else setValue(key, payload[key]);
            }
          });
        })
        .finally(() => setShow(true));
    });
  }, []);

  return (
    <div>
      {show && users && (
        <form className="p-2 md:p-12 flex flex-col items-center">
          <div className="flex justify-between items-center w-full mb-4 items-start gap-4">
            <Controller
              name={`year`}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mb-24"
                  label="Año del Consejo Directivo"
                  error={!!errors?.year}
                  helperText={errors?.year?.message}
                  variant="outlined"
                />
              )}
            />
            <div className="mt-2">
              <Button
                disabled={!isValid}
                variant="contained"
                size="large"
                onClick={() => {
                  handleUpdate();
                }}
              >
                Actualizar Consejo Directivo
              </Button>
            </div>
          </div>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 400 }}>
              <TableHead>
                <TableRow>
                  <TableCell>Cargo</TableCell>
                  <TableCell>Miembro</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {membersKeys?.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Controller
                        name={`${row.key}.position`}
                        control={control}
                        defaultValue={row.id}
                        render={({ field }) => <p {...field}>{row.label}</p>}
                      />
                    </TableCell>
                    <TableCell>
                      <Controller
                        name={`${row.key}.id_member`}
                        control={control}
                        render={({ field }) => (
                          <>
                            <Select
                              {...field}
                              className="w-full"
                              value={watch(`${row.key}.id_member`)}
                              error={!!errors[`${row.key}`]?.id_member?.message}
                              onChange={(event, newValue) => {
                                setValue(
                                  `${row.key}.id_member`,
                                  newValue.props.value
                                );
                                setError(`${row.key}.id_member`, null);
                              }}
                            >
                              {users?.map((item, index) => (
                                <MenuItem key={index} value={item._id}>
                                  {item.lastname} {item.name}
                                </MenuItem>
                              ))}
                            </Select>
                            {!!errors[`${row.key}`]?.id_member?.message && (
                              <p className="text-red">
                                {errors[`${row.key}`]?.id_member?.message}
                              </p>
                            )}
                          </>
                        )}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </form>
      )}
    </div>
  );
};

export default Miembros;
