import ImageTitle from "../shared-components/ImageTitle";
import * as React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import _ from "lodash";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import SaveIcon from "@mui/icons-material/Save";
import { TextField, CircularProgress, Paper } from "@mui/material";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useParams } from "react-router-dom";
import { getUserById, updateUser } from "../../redux/slices/userSlice";
import { showMessage } from "../../redux/slices/messageSlice";
import {
  cloudinary_constant,
  genders,
  isAdmin,
  isFinance,
  isInvestigation,
  isSecretary,
  isUser,
  tokenDecode,
} from "../../helpers/helpers";
import ChangePasswordModal from "./components/ChangePasswordModal";

const type_payment = [
  { label: "Cuota Anual", value: 1 },
  { label: "Cuota de membresía", value: 2 },
];

const status_payment = [
  { label: "Realizado", value: 1 },
  { label: "Pendiente", value: 2 },
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 4,
};

const UsuarioHome = () => {
  const dispatch = useDispatch();

  const userData = useSelector(({ user }) => user.user);

  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [indexPayment, setIndexPayment] = React.useState(0);

  const yearsArray = () => {
    const currentYear = new Date().getFullYear();
    const startYear = 2017;
    const years = [];

    for (let year = startYear; year <= currentYear; year++) {
      years.push(year);
    }

    return years.reverse();
  };

  const handleClose = () => {
    setOpen(false);
  };

  let id = useParams().id ?? tokenDecode.id;

  const paymentSchema = yup.object().shape({
    year_pay: yup.mixed().required("Seleccione el año de pago"),
    amount: yup.string().required("Ingrese el monto pagado"),
    date_pay: yup
      .string()
      .test("valid-date", "La fecha ingresada no es válida", function (value) {
        if (!value) return true; // Permitir que el campo sea nulo (null)
        return moment(value, "DD-MM-YYYY", true).isValid();
      }),
    type_pay: yup.number().required("Seleccione un tipo de pago"),
    status: yup.number().required("Seleccione el estado de pago"),
    link_voucher: yup.string(),
    id: yup.mixed(),
  });

  const defaultValuesPayment = {
    year_pay: "",
    amount: "",
    date_pay: "",
    type_pay: 1,
    status: 1,
    link_voucher: "",
    id: 0,
  };

  const schema = yup.object().shape({
    name: yup.string().required("Debe ingresar su(s) nombre(s)"),
    lastname: yup.string().required("Debe ingresar su(s) apellido(s)"),
    gender: yup.string().required("Debe seleccionar un género"),
    email: yup
      .string()
      .email("Debe ingresar un email válido")
      .required("Debe ingresar su email"),
    dni: yup
      .string()
      .matches(/^[0-9]+$/, "El DNI debe contener solo números")
      .required("Debe ingresar su DNI")
      .test("digits", "El DNI debe ser un número válido", function (value) {
        if (isNaN(value)) {
          return false;
        }
        const stringValue = String(value);
        return stringValue.length === 8;
      }),
    CMP: yup
      .string()
      .matches(/^[0-9]+$/, "El número de CMP debe contener solo números")
      .required("Debe ingresar su número de CMP"),
    RNE: yup
      .string()
      .matches(/^[0-9]+$/, "El número de RNE debe contener solo números")
      .required("Debe ingresar su número de RNE"),
    code: yup.string().required("Debe ingresar su número de Registro"),
    date_admission: yup
      .string()
      .required("El campo fecha de admisión es requerido")
      .test("valid-date", "La fecha ingresada no es válida", function (value) {
        return moment(value, "DD-MM-YYYY", true).isValid();
      }),
    birthday: yup
      .string()
      .test("valid-date", "La fecha ingresada no es válida", function (value) {
        if (!value) return true;
        return moment(value, "DD-MM-YYYY", true).isValid();
      }),
    phone: yup
      .string()
      .test(
        "is-empty",
        "Debe ingresar un número de teléfono válido",
        function (value) {
          if (value) {
            return /^\+?[0-9]+$/.test(value);
          }
          return true;
        }
      ),
    photo: yup.string(),
    address: yup.string(),
    payments: yup.array().of(paymentSchema),
  });

  const defaultValues = {
    name: "",
    lastname: "",
    gender: "",
    email: "",
    dni: "",
    CMP: "",
    RNE: "",
    code: "",
    date_admission: "",
    birthday: "",
    phone: "",
    photo: "",
    address: "",
    payments: [],
  };

  const paymentForm = useForm({
    mode: "onChange",
    defaultValuesPayment,
    resolver: yupResolver(paymentSchema),
  });

  const {
    control,
    formState,
    handleSubmit,
    setError,
    setValue,
    getValues,
    watch,
    trigger,
  } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;

  const paymentsWatcher = watch("payments");

  const handleDeletePayment = (i) => {
    const updatedPayments = paymentsWatcher.filter(
      (item, index) => index !== i
    );
    setValue("payments", updatedPayments);
  };

  const handleUploadPhoto = () =>
    window.cloudinary.openUploadWidget(
      cloudinary_constant("users-preset", true),
      (err, result) => {
        if (!err && result?.event === "success") {
          setValue("photo", result.info.secure_url);
          trigger();
        }
      }
    );

  function onSubmit() {
    const compareByYear = (a, b) => {
      return parseInt(b.year_pay) - parseInt(a.year_pay);
    };
    setValue("payments", paymentsWatcher.sort(compareByYear));
    dispatch(updateUser({ id: id, data: getValues() }))
      .unwrap()
      .then(() =>
        dispatch(
          showMessage({
            message: "Se ha guardado con éxito",
            autoHideDuration: 4000,
            severety: "success",
          })
        )
      )
      .catch((error) =>
        dispatch(
          showMessage({
            message: error.message,
            autoHideDuration: 4000,
            severety: "error",
          })
        )
      );
  }

  React.useEffect(() => {
    if (id)
      dispatch(getUserById(id)).then(({ payload }) =>
        Object.keys(defaultValues).forEach((key) => {
          if (payload.hasOwnProperty(key)) {
            setValue(key, payload[key]);
          }
        })
      );
  }, [id]);

  return (
    <div>
      <ImageTitle
        title={useParams().id ? "ADMINISTRADOR DE USUARIOS" : "MI PERFIL"}
        img="assets/images/usuarios.jpg"
      />
      <div className="p-12 flex flex-col items-center">
        <form
          name="loginForm"
          noValidate
          className="flex flex-col justify-center w-full gap-8 mb-8"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="flex justify-end gap-4">
            {id === tokenDecode.id && <ChangePasswordModal id={id} />}
            <Button
              variant="contained"
              color="secondary"
              className=" w-[140px] mt-16 rounded-lg font-bold"
              disabled={!isValid || loading}
              type="submit"
              size="large"
              startIcon={<SaveIcon />}
            >
              {loading ? (
                <CircularProgress size={27} />
              ) : (
                <p className="font-bold">Guardar</p>
              )}
            </Button>
          </div>
          <div className="flex w-full flex-col md:flex-row gap-4 items-center">
            <div className="w-[200px] h-[200px] overflow-hidden">
              <img
                onClick={(e) => {
                  handleUploadPhoto();
                }}
                alt="Imagen no encontrada"
                className="object-cover w-full h-full hover:cursor-pointer hover:shadow-lg"
                src={
                  watch("photo") ? watch("photo") : "/assets/images/user.jpg"
                }
              />
            </div>

            <div className="grid grid-cols-1 gap-4 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 w-full">
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    disabled={isUser() || isInvestigation()}
                    className="mb-24"
                    label="Nombre(s)"
                    error={!!errors.name}
                    helperText={errors?.name?.message}
                    variant="outlined"
                    required
                  />
                )}
              />
              <Controller
                name="lastname"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    disabled={isUser() || isInvestigation()}
                    className="mb-24"
                    label="Apellido(s)"
                    error={!!errors.lastname}
                    helperText={errors?.lastname?.message}
                    variant="outlined"
                    required
                  />
                )}
              />
              <Controller
                name={"gender"}
                control={control}
                render={({ field }) => (
                  <FormControl>
                    <InputLabel>Género *</InputLabel>
                    <Select
                      label="Género *"
                      disabled={isUser() || isInvestigation()}
                      {...field}
                      defaultValue=""
                      className="w-full"
                    >
                      {genders?.map((item, index) => (
                        <MenuItem key={index} value={item.value}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    className="mb-24"
                    label="Email"
                    error={!!errors.email}
                    helperText={errors?.email?.message}
                    variant="outlined"
                    required
                  />
                )}
              />
              <Controller
                name="dni"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    disabled={isUser() || isInvestigation()}
                    className="mb-24"
                    label="Número de DNI"
                    error={!!errors.dni}
                    helperText={errors?.dni?.message}
                    variant="outlined"
                    required
                  />
                )}
              />
              <Controller
                name="CMP"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    disabled={isUser() || isInvestigation()}
                    className="mb-24"
                    label="Número de CMP"
                    error={!!errors.CMP}
                    helperText={errors?.CMP?.message}
                    variant="outlined"
                    required
                  />
                )}
              />
              <Controller
                name="RNE"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    disabled={isUser() || isInvestigation()}
                    className="mb-24"
                    label="Número de RNE"
                    error={!!errors.RNE}
                    helperText={errors?.RNE?.message}
                    variant="outlined"
                    required
                  />
                )}
              />
              <Controller
                name="code"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    disabled={isUser() || isInvestigation()}
                    className="mb-24"
                    label="Número de Registro"
                    error={!!errors.code}
                    helperText={errors?.code?.message}
                    variant="outlined"
                    required
                  />
                )}
              />
              <Controller
                name="date_admission"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    disabled={isUser() || isInvestigation()}
                    className="mb-24"
                    label="Fecha de Ingreso"
                    error={!!errors.date_admission}
                    helperText={errors?.date_admission?.message}
                    variant="outlined"
                    placeholder="DD-MM-YYYY"
                    required
                  />
                )}
              />

              <Controller
                name="birthday"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    className="mb-24"
                    label="Fecha de Nacimiento"
                    error={!!errors.birthday}
                    helperText={errors?.birthday?.message}
                    variant="outlined"
                    placeholder="DD-MM-YYYY"
                  />
                )}
              />
              <Controller
                name="phone"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    className="mb-24"
                    label="Número de Celular"
                    error={!!errors.phone}
                    helperText={errors?.phone?.message}
                    variant="outlined"
                  />
                )}
              />
              <Controller
                name="address"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    className="mb-24"
                    label="Dirección"
                    error={!!errors.address}
                    helperText={errors?.address?.message}
                    variant="outlined"
                  />
                )}
              />
            </div>
          </div>
        </form>
        {(isAdmin() || isFinance()) && (
          <div className="flex w-full justify-end mb-4">
            <Button
              variant="outlined"
              color="secondary"
              className=" w-[160px] mt-16 rounded-lg font-bold"
              size="small"
              onClick={() => {
                paymentForm.reset();
                paymentForm.setValue("id", Date.now());
                setOpen(true);
              }}
              startIcon={<AddCircleOutlineIcon />}
            >
              <p className="font-bold">Agregar Pago</p>
            </Button>
          </div>
        )}
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 400 }}>
            <TableHead>
              <TableRow>
                {(isAdmin() || isFinance()) && <TableCell />}
                <TableCell>Año de pago</TableCell>
                <TableCell>Importe</TableCell>
                <TableCell>Fecha de Pago</TableCell>
                <TableCell>Tipo de Pago</TableCell>
                <TableCell>Estado</TableCell>
                <TableCell>Link de voucher</TableCell>
              </TableRow>
            </TableHead>
            {(isAdmin() || isFinance()) &&
              paymentsWatcher &&
              paymentsWatcher.length > 0 && (
                <TableBody>
                  {paymentsWatcher?.map((row, index) => (
                    <TableRow key={`${row.year_pay}${row.id}`}>
                      <TableCell>
                        <IconButton
                          color="warning"
                          onClick={() => {
                            setIndexPayment(index);
                            setOpenDelete(true);
                          }}
                        >
                          <DeleteIcon fontSize="inherit" />
                        </IconButton>
                      </TableCell>
                      <TableCell>
                        <Controller
                          name={`payments[${index}].year_pay`}
                          control={control}
                          render={({ field }) => (
                            <FormControl>
                              <InputLabel>Año de pago *</InputLabel>
                              <Select
                                label="Año de pago *"
                                {...field}
                                className="w-full"
                              >
                                {yearsArray()?.map((item, index) => (
                                  <MenuItem key={index} value={item}>
                                    {item}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          )}
                        />
                      </TableCell>
                      <TableCell>
                        <Controller
                          name={`payments[${index}].amount`}
                          control={control}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              className="mb-24"
                              label="Importe"
                              error={!!errors.payments?.[index]?.amount}
                              helperText={
                                errors?.payments?.[index]?.amount?.message
                              }
                              variant="outlined"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    S/.
                                  </InputAdornment>
                                ),
                              }}
                              required
                            />
                          )}
                        />
                      </TableCell>
                      <TableCell>
                        <Controller
                          name={`payments[${index}].date_pay`}
                          control={control}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              className="mb-24"
                              label="Fecha de pago"
                              error={!!errors.payments?.[index]?.date_pay}
                              helperText={
                                errors?.payments?.[index]?.date_pay?.message
                              }
                              variant="outlined"
                              placeholder="DD-MM-YYYY"
                            />
                          )}
                        />
                      </TableCell>
                      <TableCell>
                        <Controller
                          name={`payments[${index}].type_pay`}
                          control={control}
                          render={({ field }) => (
                            <Select {...field} className="w-full">
                              {type_payment.map((item, index) => (
                                <MenuItem key={index} value={item.value}>
                                  {item.label}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                        />
                      </TableCell>
                      <TableCell>
                        <Controller
                          name={`payments[${index}].status`}
                          control={control}
                          render={({ field }) => (
                            <Select {...field} className="w-full">
                              {status_payment.map((item, index) => (
                                <MenuItem key={index} value={item.value}>
                                  {item.label}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                        />
                      </TableCell>
                      <TableCell>
                        <Controller
                          name={`payments[${index}].link_voucher`}
                          control={control}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              className="mb-24"
                              label="Link del voucher"
                              error={!!errors.payments?.[index]?.link_voucher}
                              helperText={
                                errors?.payments?.[index]?.link_voucher?.message
                              }
                              variant="outlined"
                            />
                          )}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              )}
            {(isUser() || isSecretary()) &&
              paymentsWatcher &&
              paymentsWatcher.length > 0 && (
                <TableBody>
                  {paymentsWatcher.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>{row.year_pay}</TableCell>
                      <TableCell>S/. {row.amount}</TableCell>
                      <TableCell>{row.date_pay}</TableCell>
                      <TableCell>
                        {type_payment?.find(
                          (item) => item.value == row.type_pay
                        )?.label ?? ""}
                      </TableCell>
                      <TableCell>
                        {status_payment?.find(
                          (item) => item.value == row.status
                        )?.label ?? ""}
                      </TableCell>
                      <TableCell
                        className={row.link_voucher && "hover:cursor-pointer"}
                        onClick={() => {
                          if (row.link_voucher)
                            window.open(row.link_voucher, "_blank");
                        }}
                      >
                        {row.link_voucher}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              )}
          </Table>
        </TableContainer>
        {!(paymentsWatcher && paymentsWatcher.length > 0) && (
          <div className="mt-4">
            <Typography variant="h4">No hay pagos registrados</Typography>
          </div>
        )}
      </div>

      {open && (
        <Modal open={open} onClose={handleClose}>
          <Box sx={style}>
            <div className="flex flex-col items-center w-full max-w-320 sm:w-320 mx-auto sm:mx-0">
              <form
                name="loginForm"
                noValidate
                className="flex flex-col justify-center w-full gap-4"
              >
                <div className="flex flex-col gap-4">
                  <Controller
                    name={`year_pay`}
                    control={paymentForm.control}
                    render={({ field }) => (
                      <FormControl>
                        <InputLabel>Año de pago *</InputLabel>
                        <Select
                          label="Año de pago *"
                          {...field}
                          defaultValue={0}
                          className="w-full"
                        >
                          {yearsArray()?.map((item, index) => (
                            <MenuItem key={index} value={item}>
                              {item}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                  <Controller
                    name={`amount`}
                    control={paymentForm.control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        className="mb-24"
                        label="Importe"
                        error={!!paymentForm.formState.errors?.amount}
                        helperText={
                          paymentForm.formState.errors?.amount?.message
                        }
                        variant="outlined"
                        required
                      />
                    )}
                  />
                  <Controller
                    name={`date_pay`}
                    control={paymentForm.control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        className="mb-24"
                        label="Fecha de pago"
                        error={!!paymentForm.formState.errors?.date_pay}
                        helperText={
                          paymentForm.formState.errors?.date_pay?.message
                        }
                        variant="outlined"
                        placeholder="DD-MM-YYYY"
                      />
                    )}
                  />
                  <Controller
                    name={`type_pay`}
                    control={paymentForm.control}
                    defaultValue={1}
                    render={({ field }) => (
                      <FormControl>
                        <InputLabel>Tipo de pago *</InputLabel>
                        <Select
                          label="Tipo de pago *"
                          {...field}
                          defaultValue={1}
                          className="w-full"
                        >
                          {type_payment?.map((item, index) => (
                            <MenuItem key={index} value={item.value}>
                              {item.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                  <Controller
                    name={`status`}
                    control={paymentForm.control}
                    defaultValue={1}
                    render={({ field }) => (
                      <FormControl>
                        <InputLabel>Estado *</InputLabel>
                        <Select label="Estado *" {...field} className="w-full">
                          {status_payment?.map((item, index) => (
                            <MenuItem key={index} value={item.value}>
                              {item.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                  <Controller
                    name={`link_voucher`}
                    control={paymentForm.control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        className="mb-24"
                        label="Link del voucher"
                        error={!!paymentForm.formState.errors?.link_voucher}
                        helperText={
                          paymentForm.formState.errors?.link_voucher?.message
                        }
                        variant="outlined"
                      />
                    )}
                  />
                </div>
                <div className="flex gap-12">
                  <Button
                    variant="contained"
                    className=" w-full mt-16 rounded-lg font-bold"
                    size="large"
                    disabled={
                      !!paymentForm.errors || !paymentForm.formState.isValid
                    }
                    onClick={() => {
                      setValue("payments", [
                        ...paymentsWatcher,
                        paymentForm.getValues(),
                      ]);
                      dispatch(
                        showMessage({
                          message: "El pago ha sido añadido",
                          autoHideDuration: 4000,
                          severety: "success",
                        })
                      );
                      handleClose();
                    }}
                  >
                    <p className="font-bold">Guardar</p>
                  </Button>
                  <Button
                    variant="outlined"
                    className=" w-full mt-16 rounded-lg font-bold"
                    size="large"
                    onClick={handleClose}
                  >
                    <p className="font-bold">Cancelar</p>
                  </Button>
                </div>
              </form>
            </div>
          </Box>
        </Modal>
      )}
      {openDelete && (
        <Modal open={openDelete} onClose={() => setOpenDelete(false)}>
          <Box sx={style}>
            ¿Seguro que desea borrar este Pago?
            <div className="flex justify-end mt-4">
              <Button
                variant="contained"
                color="error"
                onClick={() => {
                  handleDeletePayment(indexPayment);
                  setOpenDelete(false);
                  dispatch(
                    showMessage({
                      message: "El pago ha sido borrado",
                      autoHideDuration: 4000,
                      severety: "success",
                    })
                  );
                }}
              >
                Borrar
              </Button>
            </div>
          </Box>
        </Modal>
      )}
    </div>
  );
};

export default UsuarioHome;
