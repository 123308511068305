import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import _ from "lodash";
import {
  ACTIVITY_STATE,
  cloudinary_constant,
  dark,
} from "../../../helpers/helpers";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showMessage } from "../../../redux/slices/messageSlice";
import {
  createPhotosDirector,
  deletePhotosDirector,
  getPhotosDirectors,
  updatePhotosDirector,
} from "../../../redux/slices/photoDirectorSlice";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 4,
};

const Foto = ({ id = "", length, isNew, setIsNew }) => {
  const dispatch = useDispatch();

  const photos = useSelector(
    ({ photosDirector }) => photosDirector.photosDirectors
  );
  const photo = photos?.find((item) => item._id == id);

  const [canEdit, setCanEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const defaultValues = {
    photo: "",
    order: length,
  };

  const schema = yup.object().shape({
    photo: yup.string().required("Debe ingresar una foto"),
    order: yup.string().required("Debe ingresar el orden"),
  });

  const {
    control,
    formState,
    handleSubmit,
    setError,
    setValue,
    getValues,
    watch,
    trigger,
  } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;

  function onSubmit() {
    if (id) {
      dispatch(updatePhotosDirector({ id: id, data: getValues() }))
        .then(() => {
          dispatch(
            showMessage({
              message: "Se ha guardado con éxito",
              autoHideDuration: 4000,
              severety: "success",
            })
          );
          dispatch(getPhotosDirectors());
          setCanEdit(false);
        })
        .catch((error) =>
          dispatch(
            showMessage({
              message: error.message,
              autoHideDuration: 4000,
              severety: "error",
            })
          )
        );
    } else {
      dispatch(createPhotosDirector(getValues()))
        .then(() => {
          dispatch(
            showMessage({
              message: "Actividad registrada con éxito",
              autoHideDuration: 4000,
              severety: "success",
            })
          );
          dispatch(getPhotosDirectors());
          setIsNew(false);
        })
        .catch((error) =>
          dispatch(
            showMessage({
              message: error.message,
              autoHideDuration: 4000,
              severety: "error",
            })
          )
        );
    }
  }

  const handleUploadPhoto = () =>
    window.cloudinary.openUploadWidget(
      cloudinary_constant("directive-preset", false),
      (err, result) => {
        if (!err && result?.event === "success") {
          setValue("photo", result.info.secure_url);
          trigger();
        }
      }
    );

  const setKeysValues = () => {
    Object.keys(defaultValues).forEach((key) => {
      if (photo.hasOwnProperty(key)) {
        setValue(key, photo[key]);
      }
    });
  };

  useEffect(() => {
    if (id) setKeysValues();
  }, [id]);

  return (
    <div className="flex w-full justify-center py-16 gap-4 flex-wrap">
      <img
        className={`max-w-[400px] max-h-[300px] md:max-w-[600px] md:max-h-[450px]`}
        src={watch("photo") ? watch("photo") : "/assets/images/image.jpg"}
        alt="Imagen no encontrada"
      />
      <div className=" flex flex-col max-w-[600px] gap-8">
        {canEdit || isNew ? (
          <div className="flex gap-4">
            <Button
              variant="contained"
              color="secondary"
              className=" w-[140px] mt-16 rounded-lg font-bold"
              disabled={!isValid}
              onClick={onSubmit}
              size="large"
              startIcon={<SaveIcon />}
            >
              {loading ? (
                <CircularProgress size={27} />
              ) : (
                <p className="font-bold">Guardar</p>
              )}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              className=" w-[140px] mt-16 rounded-lg font-bold"
              disabled={loading}
              onClick={() => {
                if (id) {
                  setKeysValues();
                  setCanEdit(false);
                } else setIsNew(false);
              }}
              size="large"
            >
              {loading ? (
                <CircularProgress size={27} />
              ) : (
                <p className="font-bold">Cancelar</p>
              )}
            </Button>
          </div>
        ) : (
          <div className="flex gap-4">
            <Button
              variant="contained"
              color="secondary"
              className=" w-[140px] mt-16 rounded-lg font-bold"
              onClick={() => setCanEdit(true)}
              size="large"
              startIcon={<BorderColorIcon />}
            >
              <p className="font-bold">Editar</p>
            </Button>
            <Button
              variant="contained"
              color="secondary"
              className=" w-[140px] mt-16 rounded-lg font-bold"
              onClick={() => setOpenDelete(true)}
              size="large"
              startIcon={<DeleteIcon />}
            >
              <p className="font-bold">Eliminar</p>
            </Button>
          </div>
        )}
        <div className="flex gap-4">
          {(canEdit || isNew) && (
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                handleUploadPhoto();
              }}
              startIcon={<CloudUploadIcon />}
            >
              <p className="font-bold">Subir Imagen</p>
            </Button>
          )}
          <Controller
            name="order"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                disabled={!(canEdit || isNew)}
                type="number"
                className="mb-24"
                label="Orden"
                error={!!errors.order}
                helperText={errors?.order?.message}
                variant="outlined"
                required
              />
            )}
          />
        </div>
      </div>
      {openDelete && (
        <Modal open={openDelete} onClose={handleCloseDelete}>
          <Box sx={style}>
            ¿Seguro que desea borrar esta foto? Este proceso es irreversible.
            <div className="flex justify-end mt-4 gap-6">
              <Button
                variant="contained"
                color="error"
                onClick={() => {
                  dispatch(deletePhotosDirector(id))
                    .then((response) => {
                      dispatch(
                        showMessage({
                          message: "La foto ha sido eliminada con exito",
                          autoHideDuration: 4000,
                          severety: "success",
                        })
                      );
                      dispatch(getPhotosDirectors());
                      setOpenDelete(false);
                    })
                    .catch(() => {
                      dispatch(
                        showMessage({
                          message: "Ha ocurrido un error",
                          autoHideDuration: 4000,
                          severety: "error",
                        })
                      );
                    });
                }}
              >
                Eliminar
              </Button>
              <Button
                variant="outlined"
                color="error"
                onClick={() => {
                  setOpenDelete(false);
                }}
              >
                Cancelar
              </Button>
            </div>
          </Box>
        </Modal>
      )}
    </div>
  );
};

export default Foto;
