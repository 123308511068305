import ImageTitle from "../shared-components/ImageTitle";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getInvestigations } from "../../redux/slices/investigationSlice";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Button } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import AdministrarInvestigacionElement from "./components/AdministrarInvestigacionElement";

const AdministrarInvestigacionPage = () => {
  const dispatch = useDispatch();
  const investigations = useSelector(
    ({ investigation }) => investigation.investigations
  );
  const [investigationState, setInvestigationState] = useState("associates");
  const [newInvestigation, setNewInvestigation] = useState(false);

  useEffect(() => {
    dispatch(getInvestigations());
  }, [dispatch]);

  return (
    <div>
      <ImageTitle
        title="ADMINISTRADOR DE INVESTIGACIÓN"
        img="assets/images/investigacion.jpg"
      />
      <div className="flex justify-around ">
        <div className="flex flex-col items-center w-full">
          <div className={`p-3 w-full flex flex-col items-center border-b-2`}>
            <span className={`flex gap-2 p-2 items-center `}>
              <p className={`text-lg md:text-2xl font-medium`}>
                ARTÍCULOS SPEAS
              </p>
            </span>
          </div>
          <div className="w-full flex">
            <div
              onClick={() => setInvestigationState("associates")}
              className={`p-3 w-full flex flex-col items-center hover:bg-gray-100 hover:cursor-pointer`}
            >
              <span
                className={`flex gap-2 p-2 items-center ${
                  investigationState == "associates" &&
                  "border-b-[6px] border-speasColors-primary"
                }`}
              >
                <p className={`text-lg md:text-2xl font-medium`}>ASOCIADOS</p>
              </span>
            </div>
            <div
              onClick={() => setInvestigationState("residents")}
              className={`p-3 w-full flex flex-col items-center hover:bg-gray-100 hover:cursor-pointer`}
            >
              <span
                className={`flex gap-2 p-2 items-center ${
                  investigationState == "residents" &&
                  "border-b-[6px] border-speasColors-primary"
                }`}
              >
                <p className={`text-lg md:text-2xl font-medium`}>RESIDENTES</p>
              </span>
            </div>
          </div>
        </div>

        <div
          onClick={() => setInvestigationState("noSpeas")}
          className={`p-3 w-full flex flex-col items-center justify-center hover:bg-gray-100 hover:cursor-pointer`}
        >
          <span
            className={`flex gap-2 p-2 items-center ${
              investigationState == "noSpeas" &&
              "border-b-[6px] border-speasColors-primary"
            }`}
          >
            <p className={`text-lg md:text-2xl font-medium`}>
              ARTÍCULOS NO SPEAS
            </p>
          </span>
        </div>
      </div>

      <div className="p-8 flex justify-end">
        <Button
          variant="contained"
          color="secondary"
          className=" w-[320px] mt-16 rounded-lg font-bold"
          disabled={newInvestigation}
          onClick={() => {
            setNewInvestigation(true);
          }}
          size="large"
          startIcon={<AddCircleOutlineIcon />}
        >
          <p className="font-bold">Registrar nuevo Artículo</p>
        </Button>
      </div>
      <div className="px-8">
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>
                  <p className="font-bold text-lg">Autores</p>
                </TableCell>
                <TableCell>
                  <p className="font-bold text-lg">Fecha de publicación</p>
                </TableCell>
                <TableCell>
                  <p className="font-bold text-lg">Título</p>
                </TableCell>
                <TableCell>
                  <p className="font-bold text-lg">Link del artículo</p>
                </TableCell>
                <TableCell>
                  <p className="font-bold text-lg">Tipo</p>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {newInvestigation && (
                <AdministrarInvestigacionElement
                  isNew={newInvestigation}
                  setIsNew={setNewInvestigation}
                />
              )}
              {investigations &&
                investigations.length > 0 &&
                investigations?.filter(
                  (item) => item.type == investigationState
                ).length > 0 &&
                investigations
                  ?.filter((item) => item.type == investigationState)
                  ?.map((item, index) => (
                    <AdministrarInvestigacionElement
                      key={index}
                      id={item._id}
                    />
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
        {investigations?.filter((item) => item.type == investigationState)
          .length == 0 && (
          <div className="flex flex-col items-center mt-12">
            <EventBusyIcon fontSize="inherit" style={{ fontSize: "100px" }} />
            <p className="font-extrabold text-4xl">
              No hay artículos registrados
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default AdministrarInvestigacionPage;
