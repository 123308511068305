import { Grid } from "@mui/material";
import ButtonSpeas from "../../shared-components/ButtonSpeas";
import { dark } from "../../../helpers/helpers";
import { useSelector } from "react-redux";

const ActividadElement = ({ id = "", index, data = null }) => {
  const activities = useSelector(({ activity }) => activity.activities);
  const activity = data ?? activities?.find((item) => item._id == id);
  return (
    <Grid xs={12} container spacing={2}>
      <Grid item xs={6} style={{ display: "flex" }}>
        <div className="w-full h-full flex flex-col justify-center px-20 py-8">
          <p
            className={`text-2xl lg:text-4xl text-${dark(
              index,
              "[#00186D]"
            )} font-bold`}
          >
            {activity.title}
          </p>
          <p className={`mt-4 text-xl text-${dark(index, "black")}`}>
            {activity.description}
          </p>
          {activity.inscription && (
            <div className="flex justify-center mt-12">
              <ButtonSpeas
                onClick={() => window.open(activity.inscription, "_blank")}
              >
                Inscripciones aquí
              </ButtonSpeas>
            </div>
          )}
        </div>
      </Grid>
      <Grid item xs={6}>
        <div className="w-full h-full flex justify-center items-center">
          <img
            className={`max-h-[95%] max-w-[95%] md:max-w-[100%] md:max-h-[90%]`}
            src={activity.image ? activity.image : "assets/images/image.jpg"}
            alt="Imagen no encontrada"
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default ActividadElement;
