import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchWithToken, fetchWithoutToken } from "../helpers/fetch";

const initialState = {
  users: [],
  user: {},
};

export const createUser = createAsyncThunk("user/create", async (data) => {
  let user;

  try {
    const response = await fetchWithToken({
      endpoint: `/user/register`,
      data: data,
      method: "POST",
    });
    user = response.data;
  } catch (error) {
    throw error;
  }

  return user;
});

export const getUsers = createAsyncThunk("users/list", async (filters) => {
  let list;

  const filter = JSON.stringify(filters);

  try {
    const response = await fetchWithoutToken({ endpoint: `/users` });
    list = response.data;
  } catch (error) {
    list = [];
  }
  return list;
});

export const getUserById = createAsyncThunk("user/one", async (id) => {
  let user = {};

  try {
    const response = await fetchWithoutToken({ endpoint: `/user/${id}` });
    user = response.data;
  } catch (error) {
    user = {};
  }
  return user;
});

export const updateUser = createAsyncThunk(
  "user/update",
  async ({ id, data }) => {
    let user = {};

    try {
      const response = await fetchWithToken({
        endpoint: `/user/update/${id}`,
        data: data,
        method: "PUT",
      });
      user = response.data;
      return user;
    } catch (error) {
      throw error;
    }
  }
);

export const updatePassword = createAsyncThunk(
  "user/updatePassword",
  async ({ id, data }) => {
    let user = {};

    try {
      const response = await fetchWithToken({
        endpoint: `/user/update_password/${id}`,
        data: data,
        method: "PUT",
      });
      user = response.data;
      return user;
    } catch (error) {
      throw error;
    }
  }
);

export const deleteUser = createAsyncThunk("user/delete", async (id) => {
  let userDeleted = {};

  try {
    const response = await fetchWithToken({
      endpoint: `/user/delete/${id}`,
      method: "DELETE",
    });
    userDeleted = response.data;
  } catch (error) {
    userDeleted = {};
  }
  return userDeleted;
});

const userSlice = createSlice({
  initialState,
  name: "user",
  reducers: {},
  extraReducers: {
    [getUsers.fulfilled]: (state, action) => {
      state.users = action.payload;
    },
    [getUserById.fulfilled]: (state, action) => {
      state.user = action.payload;
    },
  },
});

export default userSlice.reducer;
