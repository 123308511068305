import { Grid } from "swiper";
import ImageTitle from "../shared-components/ImageTitle";
import ActividadElement from "./components/ActividadElement";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getActivities } from "../../redux/slices/activitySlice";
import AdministrarActividadElement from "./components/AdministrarActividadElement";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Button, Divider, Tab, Tabs } from "@mui/material";

const ActividadesPage = () => {
  const dispatch = useDispatch();
  const activities = useSelector(({ activity }) => activity.activities);
  const [activityState, setActivityState] = useState("pending");

  useEffect(() => {
    dispatch(getActivities());
  }, [dispatch]);

  return (
    <div>
      <ImageTitle title="ACTIVIDADES" img="assets/images/actividades.jpg" />
      <div className="flex justify-around ">
        <div
          onClick={() => setActivityState("pending")}
          className={`p-3 w-full flex flex-col items-center hover:bg-gray-100 hover:cursor-pointer`}
        >
          <span
            className={`flex gap-2 p-2 items-center ${
              activityState == "pending" &&
              "border-b-[6px] border-speasColors-primary"
            }`}
          >
            <CalendarMonthIcon fontSize="large" />
            <p className={`text-xl md:text-2xl font-medium`}>PROGRAMADAS</p>
          </span>
        </div>
        <div
          onClick={() => setActivityState("executed")}
          className={`p-3 w-full flex flex-col items-center hover:bg-gray-100 hover:cursor-pointer`}
        >
          <span
            className={`flex gap-2 p-2 items-center ${
              activityState == "executed" &&
              "border-b-[6px] border-speasColors-primary"
            }`}
          >
            <EventAvailableIcon fontSize="large" />
            <p className={`text-xl md:text-2xl font-medium`}>REALIZADAS</p>
          </span>
        </div>
      </div>

      {activities &&
      activities.length > 0 &&
      activities?.filter((item) => item.state == activityState).length > 0 ? (
        activities
          ?.filter((item) => item.state == activityState)
          ?.map((item, index) => (
            <div
              key={index}
              className={`p-12 flex flex-col items-center ${
                index % 2 == 1 && "bg-[#121241]"
              }`}
            >
              <ActividadElement id={item._id} index={index} />
              <Divider />
            </div>
          ))
      ) : (
        <div className="flex flex-col items-center mt-12">
          <EventBusyIcon fontSize="inherit" style={{ fontSize: "100px" }} />
          <p className="font-extrabold text-4xl">
            No hay actividades registradas
          </p>
        </div>
      )}
    </div>
  );
};

export default ActividadesPage;
