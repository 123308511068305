import { Typography } from "@mui/material";

const ImageTitleHome = ({ img, title, height = "" }) => {
  return (
    <div
      style={{
        height: height,
        backgroundImage: `url(${img})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        backgroundSize: "cover",
      }}
    >
      <div
        style={{
          backgroundColor: "rgb(0,0,0,0.7)",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <span className="flex flex-col items-center">
          <span className="text-speasColors-primary text-2xl md:text-4xl font-extrabold mb-2 text-center">
            {title}
          </span>
          <div className="w-3/5 h-2 bg-white" />
        </span>
      </div>
      <Typography
        className="max-w-[600px] text-justify text-white"
        variant="h6"
      >
        Somos profesionales comprometidos en contribuir a mejorar la situación
        sanitaria del país y aportar en la formulación e implementación de las
        políticas de salud y a fortalecer la gestión de la prestación en
        servicios de salud.
      </Typography>
    </div>
  );
};

export default ImageTitleHome;
