import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchWithToken, fetchWithoutToken } from "../helpers/fetch";

const initialState = {
  activities: [],
  activity: {},
};

export const createActivity = createAsyncThunk(
  "activity/create",
  async (data) => {
    let activity;

    try {
      const response = await fetchWithToken({
        endpoint: `/activity/register`,
        data: data,
        method: "POST",
      });
      activity = response.data;
    } catch (error) {
      throw error;
    }

    return activity;
  }
);

export const getActivities = createAsyncThunk(
  "activities/list",
  async (filters) => {
    let list;

    const filter = JSON.stringify(filters);

    try {
      const response = await fetchWithoutToken({ endpoint: `/activities` });
      list = response.data;
    } catch (error) {
      list = [];
    }
    return list;
  }
);

export const getActivityById = createAsyncThunk("activity/id", async (id) => {
  let activity = {};

  try {
    const response = await fetchWithoutToken({ endpoint: `/activity/${id}` });
    activity = response.data;
  } catch (error) {
    activity = {};
  }
  return activity;
});

export const updateActivity = createAsyncThunk(
  "activity/update",
  async ({ id, data }) => {
    let activity = {};

    try {
      const response = await fetchWithToken({
        endpoint: `/activity/update/${id}`,
        data: data,
        method: "PUT",
      });
      activity = response.data;
      return activity;
    } catch (error) {
      throw error;
    }
  }
);

export const deleteActivity = createAsyncThunk(
  "activity/delete",
  async (id) => {
    let activityDeleted = {};

    try {
      const response = await fetchWithToken({
        endpoint: `/activity/delete/${id}`,
        method: "DELETE",
      });
      activityDeleted = response.data;
    } catch (error) {
      activityDeleted = {};
    }
    return activityDeleted;
  }
);

const activitySlice = createSlice({
  initialState,
  name: "activity",
  reducers: {},
  extraReducers: {
    [getActivities.fulfilled]: (state, action) => {
      state.activities = action.payload;
    },
    [getActivityById.fulfilled]: (state, action) => {
      state.activity = action.payload;
    },
  },
});

export default activitySlice.reducer;
