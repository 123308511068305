import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPhotosDirectors } from "../../../redux/slices/photoDirectorSlice";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ImageNotSupportedIcon from "@mui/icons-material/ImageNotSupported";
import Foto from "./Foto";
import { Button } from "@mui/material";

const Fotos = () => {
  const dispatch = useDispatch();

  const [newPhoto, setNewPhoto] = useState(false);
  const photosDirector = useSelector(
    ({ photosDirector }) => photosDirector.photosDirectors
  );
  useEffect(() => {
    dispatch(getPhotosDirectors());
  }, [dispatch]);
  return (
    <div className="p-8 w-full">
      <div className="p-8 flex justify-end">
        <Button
          variant="contained"
          color="secondary"
          className=" w-[180px] mt-16 rounded-lg font-bold"
          disabled={newPhoto}
          onClick={() => {
            setNewPhoto(true);
          }}
          size="large"
          startIcon={<AddCircleOutlineIcon />}
        >
          <p className="font-bold">Añadir Foto</p>
        </Button>
      </div>
      {newPhoto && (
        <div>
          <Foto
            length={photosDirector?.length + 1}
            isNew={true}
            setIsNew={setNewPhoto}
          />
        </div>
      )}
      {photosDirector &&
        photosDirector.length > 0 &&
        photosDirector?.map((item, index) => (
          <Foto
            key={item._id}
            id={item._id}
            length={photosDirector?.length + 1}
          />
        ))}
      {(!photosDirector || photosDirector?.length === 0) && (
        <div className="flex flex-col items-center mt-12">
          <ImageNotSupportedIcon
            fontSize="inherit"
            style={{ fontSize: "100px" }}
          />
          <p className="font-extrabold text-4xl">No se encontraron fotos</p>
        </div>
      )}
    </div>
  );
};

export default Fotos;
