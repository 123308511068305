import ImageTitle from "../shared-components/ImageTitle";

import { useState } from "react";
import ImageIcon from "@mui/icons-material/Image";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import Miembros from "./components/Miembros";
import Fotos from "./components/Fotos";

const ConsejoDirectivoHome = () => {
  const [state, setState] = useState("members");

  const view = () => {
    if (state === "members") return <Miembros />;
    return <Fotos />;
  };

  return (
    <div>
      <ImageTitle
        title="ADMINISTRADOR DE CONSEJO DIRECTIVO"
        img="assets/images/inicio.jpg"
      />
      <div className="flex justify-around ">
        <div
          onClick={() => setState("members")}
          className={`p-3 w-full flex flex-col items-center hover:bg-gray-100 hover:cursor-pointer`}
        >
          <span
            className={`flex gap-2 p-2 items-center ${
              state == "members" && "border-b-[6px] border-speasColors-primary"
            }`}
          >
            <ManageAccountsIcon fontSize="large" />
            <p className={`text-xl md:text-2xl font-medium`}>MIEMBROS</p>
          </span>
        </div>
        <div
          onClick={() => setState("photos")}
          className={`p-3 w-full flex flex-col items-center hover:bg-gray-100 hover:cursor-pointer`}
        >
          <span
            className={`flex gap-2 p-2 items-center ${
              state == "photos" && "border-b-[6px] border-speasColors-primary"
            }`}
          >
            <ImageIcon fontSize="large" />
            <p className={`text-xl md:text-2xl font-medium`}>FOTOS</p>
          </span>
        </div>
      </div>
      {view()}
    </div>
  );
};

export default ConsejoDirectivoHome;
