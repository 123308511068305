import React from "react";
import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Error404Page = () => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
      <Typography
        variant="h1"
        component="h1"
        className="text-6xl text-red-500 mb-8"
      >
        Error 404
      </Typography>
      <Typography
        variant="h4"
        component="h2"
        className="text-2xl text-gray-700 mb-4"
      >
        ¡Ups! Página no encontrada
      </Typography>
      <Typography variant="body1" className="text-gray-600 mb-6">
        Lo sentimos, la página que estás buscando no existe.
      </Typography>
      <div className="mt-12">
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate("/")}
        >
          Volver a la página principal
        </Button>
      </div>
    </div>
  );
};

export default Error404Page;
