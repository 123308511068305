import { Grid } from "@mui/material";
import ButtonSpeas from "../shared-components/ButtonSpeas";
import DividerSpeas from "../shared-components/DividerSpeas";
import ImageTitle from "../shared-components/ImageTitle";
import Title from "../shared-components/Title";
import { useNavigate } from "react-router-dom";

const NormativasPage = () => {
  const navigate = useNavigate();

  return (
    <div>
      <ImageTitle title="NORMATIVAS" img="assets/images/normativas.jpg" />
      <div className="p-12 flex flex-col items-center">
        <span
          onClick={() =>
            window.open("/assets/files/Estatuto_SPEAS.pdf", "_blank")
          }
          className="flex flex-col items-center hover:underline hover:cursor-pointer"
        >
          <img
            src="/assets/images/logo.png"
            alt="logo"
            className="w-60 md:w-80"
          />
          <span className="font-bold text-xl md:text-2xl -mt-4">
            VER ESTATUTO
          </span>
        </span>
      </div>
      <div className="p-12 flex flex-col items-center bg-[#121241]">
        <Title title="Otros Documentos" color="white" />
        <Grid container spacing={2} style={{ marginTop: "15px" }}>
          <Grid item xs={12} md={6}>
            <div className="w-full flex justify-center">
              <ButtonSpeas
                onClick={() =>
                  window.open(
                    "/assets/files/RELACION_DE_ASOCIADOS.docx",
                    "_blank"
                  )
                }
              >
                Padrón de Socios
              </ButtonSpeas>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className="w-full flex justify-center">
              <ButtonSpeas onClick={() => navigate("/convenios")}>
                Convenios
              </ButtonSpeas>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className="w-full flex justify-center">
              <ButtonSpeas onClick={() => navigate("/planes-y-proyectos")}>
                Planes y Proyectos
              </ButtonSpeas>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className="w-full flex justify-center">
              <ButtonSpeas
                onClick={() => navigate("/normativas-y-herramientas")}
              >
                <p className="font-bold">Normativas y Herramientas</p>
              </ButtonSpeas>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default NormativasPage;
