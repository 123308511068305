import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchWithToken, fetchWithoutToken } from "../helpers/fetch";

const initialState = {
  directors: [],
  director: {},
  directorFull: {},
};

export const createDirector = createAsyncThunk(
  "director/create",
  async (data) => {
    let director;

    try {
      const response = await fetchWithToken({
        endpoint: `/director/register`,
        data: data,
        method: "POST",
      });
      director = response.data;
    } catch (error) {
      throw error;
    }

    return director;
  }
);

export const getDirectors = createAsyncThunk("directors/list", async () => {
  let list;

  try {
    const response = await fetchWithoutToken({ endpoint: `/directors` });
    list = response.data;
  } catch (error) {
    list = [];
  }
  return list;
});

export const getDirectorById = createAsyncThunk("director", async (id) => {
  let director = {};

  try {
    const response = await fetchWithoutToken(`/director/${id}`);
    director = response.data;
  } catch (error) {
    director = {};
  }
  return director;
});

export const getCurrentDirector = createAsyncThunk(
  "director/current",
  async () => {
    let director;

    try {
      const response = await fetchWithoutToken({ endpoint: `/directors` });
      director = response?.data[0];
    } catch (error) {
      director = {};
    }
    return director;
  }
);

export const getFulltDirector = createAsyncThunk("director/full", async () => {
  let director;

  try {
    const response = await fetchWithoutToken({ endpoint: `/director-full` });
    director = response?.data;
  } catch (error) {
    director = {};
  }
  return director;
});

export const updateDirector = createAsyncThunk(
  "director/update",
  async ({ id, data }) => {
    let director = {};

    try {
      const response = await fetchWithToken({
        endpoint: `/director/update/${id}`,
        data: data,
        method: "PUT",
      });
      director = response.data;
      return director;
    } catch (error) {
      throw error;
    }
  }
);

const directorSlice = createSlice({
  initialState,
  name: "director",
  reducers: {},
  extraReducers: {
    [getDirectors.fulfilled]: (state, action) => {
      state.directors = action.payload;
    },
    [getDirectorById.fulfilled]: (state, action) => {
      state.director = action.payload;
    },
    [getCurrentDirector.fulfilled]: (state, action) => {
      state.director = action.payload;
    },
    [getFulltDirector.fulfilled]: (state, action) => {
      state.directorFull = action.payload;
    },
  },
});

export default directorSlice.reducer;
