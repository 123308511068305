import FacebookIcon from "@mui/icons-material/Facebook";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { IconButton } from "@mui/material";

const Footer = () => {
  return (
    <div className="bg-black flex justify-around p-4 items-center mt-auto w-full gap-4 flex-wrap">
      <img
        src="/assets/images/logo-light.png"
        alt="logo"
        className="h-[60px]"
      />
      <div className="text-white hidden md:flex">
        2023 © Todos los derechos reservados
      </div>
      <div>
        <div className="flex">
          <IconButton
            onClick={() =>
              window.open("https://www.facebook.com/speas.pe", "_blank")
            }
          >
            <FacebookIcon className="text-white" />
          </IconButton>
          {/* <IconButton>
            <WhatsAppIcon className="text-white" />
          </IconButton> */}
        </div>
        <div className="text-white">
          Contacto:{" "}
          <span className="hover:underline">secretaria@speasperu.org</span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
