import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchWithToken, fetchWithoutToken } from "../helpers/fetch";

const initialState = {
  normatives: [],
  normative: {},
};

export const createNormative = createAsyncThunk(
  "normative/create",
  async (data) => {
    let normative;

    try {
      const response = await fetchWithToken({
        endpoint: `/normative/register`,
        data: data,
        method: "POST",
      });
      normative = response.data;
    } catch (error) {
      throw error;
    }

    return normative;
  }
);

export const getNormatives = createAsyncThunk(
  "normatives/list",
  async (filters) => {
    let list;

    const filter = JSON.stringify(filters);

    try {
      const response = await fetchWithoutToken({ endpoint: `/normatives` });
      list = response.data;
    } catch (error) {
      list = [];
    }
    return list;
  }
);

export const getNormativeById = createAsyncThunk("normative", async (id) => {
  let normative = {};

  try {
    const response = await fetchWithoutToken(`/normative/${id}`);
    normative = response.data;
  } catch (error) {
    normative = {};
  }
  return normative;
});

export const updateNormative = createAsyncThunk(
  "normative/update",
  async ({ id, data }) => {
    let normative = {};

    try {
      const response = await fetchWithToken({
        endpoint: `/normative/update/${id}`,
        data: data,
        method: "PUT",
      });
      normative = response.data;
      return normative;
    } catch (error) {
      throw error;
    }
  }
);

export const deleteNormative = createAsyncThunk(
  "normative/delete",
  async (id) => {
    let normativeDeleted = {};

    try {
      const response = await fetchWithToken({
        endpoint: `/normative/delete/${id}`,
        method: "DELETE",
      });
      normativeDeleted = response.data;
    } catch (error) {
      normativeDeleted = {};
    }
    return normativeDeleted;
  }
);

const normativeSlice = createSlice({
  initialState,
  name: "normative",
  reducers: {},
  extraReducers: {
    [getNormatives.fulfilled]: (state, action) => {
      state.normatives = action.payload;
    },
    [getNormativeById.fulfilled]: (state, action) => {
      state.normative = action.payload;
    },
  },
});

export default normativeSlice.reducer;
