import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Pagination, Navigation } from "swiper";
import SwiperCard from "./SwiperCard";
import { vowelGender, vowelGender2 } from "../../../helpers/helpers";

export default function SwiperStaff({ data }) {
  return (
    <div className="w-full">
      <Swiper
        breakpoints={{
          200: {
            width: 200,
            slidesPerView: 1,
          },
          768: {
            width: 768,
            slidesPerView: 3,
          },
        }}
        spaceBetween={30}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Pagination, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide>
          <SwiperCard
            item={data.president}
            position={`President${vowelGender2(data.president?.gender)}`}
          />
        </SwiperSlide>
        <SwiperSlide>
          <SwiperCard
            item={data.vice_president}
            position={`Vice-president${vowelGender2(
              data.vice_president?.gender
            )}`}
          />
        </SwiperSlide>
        <SwiperSlide>
          <SwiperCard
            item={data.general_secretary}
            position={`Secretari${vowelGender(
              data.general_secretary?.gender
            )} General`}
          />
        </SwiperSlide>
        <SwiperSlide>
          <SwiperCard
            item={data.finance_secretary}
            position={`Secretari${vowelGender(
              data.general_secretary?.gender
            )} de Finanzas`}
          />
        </SwiperSlide>
        <SwiperSlide>
          <SwiperCard
            item={data.scientific_secretary}
            position={`Secretari${vowelGender(
              data.general_secretary?.gender
            )} de Acción Científica`}
          />
        </SwiperSlide>
        <SwiperSlide>
          <SwiperCard
            item={data.branch_secretary}
            position={`Secretari${vowelGender(
              data.general_secretary?.gender
            )} de de Filiales`}
          />
        </SwiperSlide>
        <SwiperSlide>
          <SwiperCard
            item={data.ethics_member}
            position="Vocal de Ética y Calificación"
          />
        </SwiperSlide>
        <SwiperSlide>
          <SwiperCard
            item={data.research_member}
            position="Vocal de Investigación, Docencia y Desarrollo"
          />
        </SwiperSlide>
        <SwiperSlide>
          <SwiperCard
            item={data.past_president}
            position={`Past President${vowelGender2(
              data.past_president?.gender
            )}`}
          />
        </SwiperSlide>
      </Swiper>
    </div>
  );
}
