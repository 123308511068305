import { Grid } from "@mui/material";
import ButtonSpeas from "../shared-components/ButtonSpeas";
import DividerSpeas from "../shared-components/DividerSpeas";
import ImageTitle from "../shared-components/ImageTitle";
import Title from "../shared-components/Title";

const data = [
  {
    title: "Titular",
    content:
      "Medico Especialista de la Especialidad de Administración de Salud o Gerencia de Salud con 3 años como miembro asociado, que presentó un trabajo 5 de investigación. Tiene voz y voto, puede elegir y ser elegido.",
  },
  {
    title: "Asociado",
    content:
      "Médico Especialista de la Especialidad o de Especialidades afines a la Sociedad. Tiene voz y voto. Puede elegir pero no puede ser elegido.",
  },
  {
    title: "Adscrito",
    content: "Médico Residente de la especialidad, con voz.",
  },
];

const AsociarsePage = () => {
  return (
    <div>
      <ImageTitle title="ASOCIARSE" img="assets/images/asociarse.jpg" />
      <div className="p-12 flex flex-col items-center">
        <Title title="Tipos de Socios" />
        <Grid
          container
          justifyContent="center"
          rowSpacing={4}
          style={{ marginTop: "0px" }}
        >
          {data.map((item, index) => (
            <Grid
              key={index}
              item
              container
              direction="column"
              alignItems="center"
              justifyContent="space-between"
              xs={12}
              md={6}
              lg={4}
              rowSpacing={2}
            >
              <Grid item width="80%" justifyContent="center">
                <div className="flex flex-col items-center w-full">
                  <Title title={item.title} />
                  <span className="text-center w-11/12 text-xl">
                    {item.content}
                  </span>
                </div>
              </Grid>
            </Grid>
          ))}
          <Grid item container rowSpacing={4} xs={12} justifyContent="center">
            <Grid
              item
              xs={12}
              md={6}
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
              <div className="flex justify-center">
                <ButtonSpeas
                  onClick={() =>
                    window.open(
                      "/assets/files/REQUISITOS_INSCRIPCION_SPEAS.docx",
                      "_blank"
                    )
                  }
                >
                  Requisitos de Inscripción
                </ButtonSpeas>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              alignItems="center"
              justifyContent="space-between"
            >
              <div className="flex justify-center">
                <ButtonSpeas
                  onClick={() =>
                    window.open(
                      "/assets/files/SOLICITUD_ADMISION_SPEAS.docx",
                      "_blank"
                    )
                  }
                >
                  Formato de Inscripción
                </ButtonSpeas>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <div className="p-12 flex flex-col items-center bg-[#121241]">
        <Title title="Contacto para Información" color="white" />
        <div className="text-xl mt-8 text-white">
          <div className="my-[4px]">Email: secretaria@speasperu.org</div>
          <div className="my-[4px]">Celular: 943917158</div>
          <div className="my-[4px]">WhatsApp: 943917158</div>
        </div>
      </div>
    </div>
  );
};

export default AsociarsePage;
