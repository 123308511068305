import ImageTitle from "../shared-components/ImageTitle";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { getProjects } from "../../redux/slices/projectSlice";
import FilePresentIcon from "@mui/icons-material/FilePresent";

const ProyectoLista = () => {
  const dispatch = useDispatch();

  const projects = useSelector(({ project }) => project.projects);

  useEffect(() => {
    dispatch(getProjects());
  }, [dispatch]);

  return (
    <div>
      <ImageTitle
        title="PLANES Y PROYECTOS"
        img="assets/images/normativas.jpg"
      />
      <div className="p-8">
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <p className="font-bold text-lg">Nombre</p>
                </TableCell>
                <TableCell>
                  <p className="font-bold text-lg">Ver documento</p>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {projects &&
                projects.length > 0 &&
                projects?.map((item, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>{item.name}</TableCell>
                    <TableCell>
                      <FilePresentIcon
                        className="hover:cursor-pointer"
                        onClick={() => window.open(item.url, "_blank")}
                      />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        {projects?.length == 0 && (
          <div className="flex flex-col items-center mt-12">
            <EventBusyIcon fontSize="inherit" style={{ fontSize: "100px" }} />
            <p className="font-extrabold text-4xl">
              No hay planes o proyectos registrados
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProyectoLista;
