import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import LoginModal from "./LoginModal";
import { USER_STATUS, myStatus } from "../helpers/helpers";
import { useDispatch } from "react-redux";
import { getUserById } from "../redux/slices/userSlice";

const pages = [
  { label: "NOSOTROS", url: "/" },
  { label: "NORMATIVAS", url: "/normativas" },
  { label: "ASOCIARSE", url: "/asociarse" },
  { label: "ACTIVIDADES", url: "/actividades" },
  { label: "INVESTIGACIÓN", url: "/investigacion" },
];
const settings = [
  {
    label: "Mi Perfil",
    url: "/mi-perfil",
    roles: [
      USER_STATUS.FINANCE,
      USER_STATUS.SECRETARY,
      USER_STATUS.INVESTIGATION,
      USER_STATUS.USER,
    ],
  },
  {
    label: "Administrar Actividades",
    url: "/administrar-actividades",
    roles: [USER_STATUS.ADMIN, USER_STATUS.SECRETARY],
  },
  {
    label: "Administrar Investigación",
    url: "/administrar-investigacion",
    roles: [USER_STATUS.ADMIN, USER_STATUS.INVESTIGATION],
  },
  {
    label: "Administrar Usuarios",
    url: "/administrar-usuarios",
    roles: [USER_STATUS.ADMIN, USER_STATUS.FINANCE, USER_STATUS.SECRETARY],
  },
  {
    label: "Administrar Consejo Directivo",
    url: "/consejo-directivo",
    roles: [USER_STATUS.ADMIN, USER_STATUS.SECRETARY],
  },
  {
    label: "Administrar Convenios",
    url: "/administrar-convenios",
    roles: [USER_STATUS.ADMIN, USER_STATUS.SECRETARY],
  },
  {
    label: "Administrar Normativas y Herramientas",
    url: "/administrar-normativas-y-herramientas",
    roles: [USER_STATUS.ADMIN, USER_STATUS.SECRETARY],
  },
  {
    label: "Administrar Planes y Proyectos",
    url: "/administrar-planes-y-proyectos",
    roles: [USER_STATUS.ADMIN, USER_STATUS.SECRETARY],
  },
];

const AppBarComponent = (props) => {
  const dispatch = useDispatch();

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [isAuth, setIsAuth] = React.useState(false);

  const pathName = window.location.pathname;
  const navigate = useNavigate();
  const token = localStorage.getItem("jwt_access_token_speas");
  const name = JSON.parse(localStorage.getItem("data_user_speas"))?.name;
  const photo = JSON.parse(localStorage.getItem("data_user_speas"))?.photo;

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  React.useEffect(() => {
    if (!!token) {
      setIsAuth(true);
    } else setIsAuth(false);
  }, [token]);

  return (
    <AppBar position="static" color="inherit" className="bg-white">
      <Container maxWidth="xl">
        <Toolbar disableGutters className="h-24">
          <img
            src="/assets/images/logo-light.png"
            className="h-20 hidden md:flex"
            alt="logo"
          />

          <Box sx={{ flexGrow: 1 }} className="md:hidden">
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page, index) => (
                <MenuItem
                  key={index}
                  onClick={() => {
                    handleCloseNavMenu();
                    navigate(page.url);
                  }}
                >
                  <Typography textAlign="center">{page.label}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              justifyContent: "center",
              marginRight: 4,
            }}
          >
            <img
              src="/assets/images/logo-light.png"
              className="h-16 hidden sm:flex md:hidden"
              alt="logo"
            />
          </Box>

          <Box
            sx={{
              flexGrow: 1,
              justifyContent: "end",
              marginRight: 4,
            }}
            className="hidden md:flex"
          >
            {pages.map((page, index) => (
              <Button
                key={index}
                sx={{ my: 2, color: "black", display: "block" }}
                onClick={() => navigate(page.url)}
              >
                <span
                  className={`${pathName === page.url && "font-extrabold"}`}
                >
                  {page?.label}
                </span>
              </Button>
            ))}
          </Box>
          {isAuth ? (
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt={name} src={photo} />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting, index) => {
                  if (setting.roles?.includes(myStatus))
                    return (
                      <MenuItem
                        key={index}
                        onClick={() => {
                          navigate(setting.url);
                          handleCloseUserMenu();
                        }}
                      >
                        <Typography textAlign="center">
                          {setting.label}
                        </Typography>
                      </MenuItem>
                    );
                })}
                <MenuItem
                  onClick={() => {
                    localStorage.removeItem("jwt_access_token_speas");
                    navigate("/");
                    handleCloseUserMenu();
                  }}
                >
                  <Typography textAlign="center">Cerrar Sesión</Typography>
                </MenuItem>
              </Menu>
            </Box>
          ) : (
            <Box sx={{ flexGrow: 0 }}>
              <LoginModal />
            </Box>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default AppBarComponent;
