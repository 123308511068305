import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import PushPinIcon from "@mui/icons-material/PushPin";
import * as React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import _ from "lodash";
import moment from "moment";
import {
  ACTIVITY_STATE,
  cloudinary_constant,
  dark,
} from "../../../helpers/helpers";
import { useDispatch, useSelector } from "react-redux";
import {
  createActivity,
  deleteActivity,
  getActivities,
  updateActivity,
} from "../../../redux/slices/activitySlice";
import { showMessage } from "../../../redux/slices/messageSlice";
import { getCurrentModal, updateModal } from "../../../redux/slices/modalSlice";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 4,
};

const AdministrarActividadElement = ({
  id = "",
  isNew = false,
  setIsNew = null,
}) => {
  const dispatch = useDispatch();
  const activities = useSelector(({ activity }) => activity.activities);
  const activity = activities?.find((item) => item._id == id);
  const activityModal = useSelector(({ modal }) => modal.modal);

  const [loading, setLoading] = React.useState(false);
  const [canEdit, setCanEdit] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const defaultValues = {
    title: "",
    image: "",
    description: "",
    inscription: "",
    state: "pending",
    date: "",
  };

  const schema = yup.object().shape({
    title: yup.string().required("Debe ingresar un título"),
    image: yup.string().required("Debe ingresar una imagen"),
    description: yup.string().required("Debe ingresar una descripción"),
    inscription: yup.string(),
    state: yup.string().required("Debe seleccionar un estado"),
    date: yup
      .string()
      .required("Debe seleccionar una fecha")
      .test("valid-date", "La fecha ingresada no es válida", function (value) {
        return moment(value, "DD-MM-YYYY", true).isValid();
      }),
  });

  const {
    control,
    formState,
    handleSubmit,
    setError,
    setValue,
    getValues,
    watch,
    trigger,
  } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;

  function onSubmit() {
    if (id) {
      dispatch(updateActivity({ id: id, data: getValues() }))
        .then(() => {
          dispatch(
            showMessage({
              message: "Se ha guardado con éxito",
              autoHideDuration: 4000,
              severety: "success",
            })
          );
          dispatch(getActivities());
          setCanEdit(false);
        })
        .catch((error) =>
          dispatch(
            showMessage({
              message: error.message,
              autoHideDuration: 4000,
              severety: "error",
            })
          )
        );
    } else {
      dispatch(createActivity(getValues()))
        .then(() => {
          dispatch(
            showMessage({
              message: "Actividad registrada con éxito",
              autoHideDuration: 4000,
              severety: "success",
            })
          );
          dispatch(getActivities());
          setIsNew(false);
        })
        .catch((error) =>
          dispatch(
            showMessage({
              message: error.message,
              autoHideDuration: 4000,
              severety: "error",
            })
          )
        );
    }
  }

  const handleUploadPhoto = () =>
    window.cloudinary.openUploadWidget(
      cloudinary_constant("activities-preset", false),
      (err, result) => {
        if (!err && result?.event === "success") {
          setValue("image", result.info.secure_url);
          trigger();
        }
      }
    );

  const handlePinPost = () => {
    const idToUpdate = activityModal._id;
    const data = { id_activity: id };
    dispatch(updateModal({ id: idToUpdate, data: data }))
      .then(() => {
        dispatch(
          showMessage({
            message: "Actividad fijada al inicio",
            autoHideDuration: 4000,
            severety: "success",
          })
        );
        dispatch(getCurrentModal());
      })
      .catch((error) =>
        dispatch(
          showMessage({
            message: error.message,
            autoHideDuration: 4000,
            severety: "error",
          })
        )
      );
  };

  const setKeysValues = () => {
    Object.keys(defaultValues).forEach((key) => {
      if (activity.hasOwnProperty(key)) {
        setValue(key, activity[key]);
      }
    });
  };

  React.useEffect(() => {
    if (id) setKeysValues();
  }, [id]);

  return (
    <form>
      <Grid xs={12} container spacing={1} style={{ display: "flex" }}>
        <Grid
          item
          xs={12}
          md={6}
          style={{ display: "flex", flexDirection: "column", order: 2 }}
        >
          <div className="w-full h-full flex flex-col justify-center px-4 md:px-20 py-8 gap-4">
            <div className="flex justify-between flex-wrap gap-4">
              <Controller
                name="date"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    className="mb-24"
                    label="Fecha de inicio"
                    disabled={!(canEdit || isNew)}
                    placeholder="DD-MM-YYYY"
                    error={!!errors.date}
                    helperText={errors?.date?.message}
                    variant="outlined"
                    required
                  />
                )}
              />
              <Controller
                name="state"
                control={control}
                render={({ field }) => (
                  <FormControl>
                    <InputLabel>Estado *</InputLabel>
                    <Select
                      disabled={!(canEdit || isNew)}
                      label="Estado *"
                      {...field}
                      className="w-full"
                    >
                      {ACTIVITY_STATE.map((item, index) => (
                        <MenuItem key={index} value={item.value}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
            </div>
            <Controller
              name="title"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  disabled={!(canEdit || isNew)}
                  className="mb-24"
                  label="Título"
                  error={!!errors.title}
                  helperText={errors?.title?.message}
                  variant="outlined"
                  required
                />
              )}
            />
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  disabled={!(canEdit || isNew)}
                  className="mb-24"
                  label="Descripción"
                  multiline
                  error={!!errors.description}
                  helperText={errors?.description?.message}
                  variant="outlined"
                  required
                />
              )}
            />
            <Controller
              name="inscription"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  disabled={!(canEdit || isNew)}
                  className="mb-24"
                  label="Link de inscripcion"
                  multiline
                  error={!!errors.inscription}
                  helperText={errors?.inscription?.message}
                  variant="outlined"
                />
              )}
            />
            <div className="flex justify-between gap-x-12 gap-y-4 flex-wrap">
              {canEdit || isNew ? (
                <div className="flex gap-8">
                  <Button
                    variant="contained"
                    color="secondary"
                    className=" w-[140px] mt-16 rounded-lg font-bold"
                    disabled={!isValid || loading}
                    onClick={onSubmit}
                    size="large"
                    startIcon={<SaveIcon />}
                  >
                    {loading ? (
                      <CircularProgress size={27} />
                    ) : (
                      <p className="font-bold">Guardar</p>
                    )}
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    className=" w-[140px] mt-16 rounded-lg font-bold"
                    disabled={loading}
                    onClick={() => {
                      if (id) {
                        setKeysValues();
                        setCanEdit(false);
                      } else setIsNew(false);
                    }}
                    size="large"
                  >
                    {loading ? (
                      <CircularProgress size={27} />
                    ) : (
                      <p className="font-bold">Cancelar</p>
                    )}
                  </Button>
                </div>
              ) : (
                <div className="flex gap-8">
                  <Button
                    variant="contained"
                    color="secondary"
                    className=" w-[140px] mt-16 rounded-lg font-bold"
                    onClick={() => setCanEdit(true)}
                    size="large"
                    startIcon={<BorderColorIcon />}
                  >
                    <p className="font-bold">Editar</p>
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    className=" w-[140px] mt-16 rounded-lg font-bold"
                    onClick={() => setOpenDelete(true)}
                    size="large"
                    startIcon={<DeleteIcon />}
                  >
                    <p className="font-bold">Eliminar</p>
                  </Button>
                </div>
              )}
              {activityModal.id_activity === id && !isNew ? (
                <div className="bg-[#1ecc7e] p-3 rounded-lg text-lg flex gap-2 items-center">
                  <PushPinIcon className="text-white" />
                  <p className="font-bold text-white">PUBLICACIÓN FIJADA</p>
                </div>
              ) : (
                <Button
                  variant="contained"
                  color="secondary"
                  className=" w-[340px] mt-16 rounded-lg font-bold"
                  onClick={() => handlePinPost()}
                  disabled={canEdit}
                  size="large"
                  startIcon={<PushPinIcon />}
                >
                  <p className="font-bold">Fijar Publicación en el inicio</p>
                </Button>
              )}
            </div>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          style={{ display: "flex", flexDirection: "column", order: 1 }}
        >
          <div className="w-full h-full flex justify-center items-center">
            <img
              onClick={() => {
                if (canEdit || isNew) handleUploadPhoto();
              }}
              className={`max-h-[95%] max-w-[95%] md:max-w-[100%] md:max-h-[90%] ${
                (canEdit || isNew) && "hover:cursor-pointer hover:shadow-xl"
              }`}
              src={watch("image") ? watch("image") : "/assets/images/image.jpg"}
              alt="Imagen no encontrada"
            />
            {openDelete && (
              <Modal open={openDelete} onClose={handleCloseDelete}>
                <Box sx={style}>
                  ¿Seguro que desea borrar esta actividad? Este proceso es
                  irreversible.
                  <div className="flex justify-end mt-4 gap-6">
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => {
                        dispatch(deleteActivity(id))
                          .then((response) => {
                            dispatch(
                              showMessage({
                                message:
                                  "La actividad ha sido eliminada con exito",
                                autoHideDuration: 4000,
                                severety: "success",
                              })
                            );
                            dispatch(getActivities());
                            setOpenDelete(false);
                          })
                          .catch(() => {
                            dispatch(
                              showMessage({
                                message: "Ha ocurrido un error",
                                autoHideDuration: 4000,
                                severety: "error",
                              })
                            );
                          });
                      }}
                    >
                      Eliminar
                    </Button>
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => {
                        setOpenDelete(false);
                      }}
                    >
                      Cancelar
                    </Button>
                  </div>
                </Box>
              </Modal>
            )}
          </div>
        </Grid>
      </Grid>
    </form>
  );
};

export default AdministrarActividadElement;
