import jwt_decode from "jwt-decode";
import { Navigate } from "react-router-dom";

const token = localStorage.getItem("jwt_access_token_speas");
export const tokenDecode = token ? jwt_decode(token) : "";
export const myStatus = tokenDecode.status;

export const staffMembers = [
  "Presidente",
  "Vice-presidente",
  "Secretario General",
  "Secretario de Finanzas",
  "Secretario de Acción Científica",
  "Secretario de Filiales",
  "Vocal de Ética y Calificación",
  "Vocal de Investigación, Docencia y Desarrollo",
  "Past Presidente",
];

export const genders = [
  { value: "male", label: "Masculino" },
  { value: "female", label: "Femenino" },
  { value: "other", label: "Otro" },
];

export const ACTIVITY_STATE = [
  { value: "executed", label: "Realizado" },
  { value: "pending", label: "Programado" },
];

export const INVESTIGATION_TYPE = [
  { value: "associates", label: "SPEAS Asociados" },
  { value: "residents", label: "SPEAS Residentes Adscritos" },
  { value: "noSpeas", label: "No SPEAS" },
];

export const USER_STATUS = {
  ADMIN: 1,
  FINANCE: 2,
  SECRETARY: 3,
  INVESTIGATION: 5,
  USER: 4,
};

export const isAdmin = () => {
  return tokenDecode.status === USER_STATUS.ADMIN;
};

export const isFinance = () => {
  return tokenDecode.status === USER_STATUS.FINANCE;
};

export const isSecretary = () => {
  return tokenDecode.status === USER_STATUS.SECRETARY;
};

export const isInvestigation = () => {
  return tokenDecode.status === USER_STATUS.INVESTIGATION;
};

export const isUser = () => {
  return tokenDecode.status === USER_STATUS.USER;
};

export const PrivateRoute = ({ children, routeAux = "*", roles = [] }) => {
  if (
    (roles?.includes(USER_STATUS.ADMIN) && isAdmin()) ||
    (roles?.includes(USER_STATUS.FINANCE) && isFinance()) ||
    (roles?.includes(USER_STATUS.SECRETARY) && isSecretary()) ||
    (roles?.includes(USER_STATUS.INVESTIGATION) && isInvestigation()) ||
    (roles?.includes(USER_STATUS.USER) && isUser()) ||
    !roles
  )
    return children;
  return <Navigate to={routeAux} />;
};

export const cloudinary_constant = (upload_preset, cropping) => {
  return {
    cloudName: process.env.REACT_APP_CLOUDINARY_CLOUDNAME,
    uploadPreset: upload_preset,
    sources: ["local", "camera"],
    googleApiKey: "<image_search_google_api_key>",
    showAdvancedOptions: false,
    cropping: cropping,
    multiple: false,
    defaultSource: "local",
    styles: {
      palette: {
        window: "#FFFFFF",
        windowBorder: "#90A0B3",
        tabIcon: "#0078FF",
        menuIcons: "#5A616A",
        textDark: "#000000",
        textLight: "#FFFFFF",
        link: "#0078FF",
        action: "#FF620C",
        inactiveTabIcon: "#0E2F5A",
        error: "#F44235",
        inProgress: "#0078FF",
        complete: "#20B832",
        sourceBg: "#E4EBF1",
      },
      fonts: {
        default: null,
        "'Poppins', sans-serif": {
          url: "https://fonts.googleapis.com/css?family=Poppins",
          active: true,
        },
      },
    },
  };
};

export const vowelGender = (gender) => {
  if (gender === "male") return "o";
  else if (gender === "female") return "a";
  else return "@";
};

export const vowelGender2 = (gender) => {
  if (gender === "male") return "e";
  else if (gender === "female") return "a";
  else return "@";
};

export const dark = (index, color) => {
  if (index % 2 == 0) return color;
  return "white";
};

export const isValidURL = (url) => {
  const pattern = new RegExp("^https?:\\/\\/[a-zA-Z0-9.-]");
  return pattern.test(url);
};
