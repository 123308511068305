import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
} from "@mui/material";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import SaveIcon from "@mui/icons-material/Save";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import * as React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import _ from "lodash";
import moment from "moment";
import {
  ACTIVITY_STATE,
  INVESTIGATION_TYPE,
  cloudinary_constant,
  dark,
} from "../../../helpers/helpers";
import { useDispatch, useSelector } from "react-redux";
import {
  createInvestigation,
  deleteInvestigation,
  getInvestigations,
  updateInvestigation,
} from "../../../redux/slices/investigationSlice";
import { showMessage } from "../../../redux/slices/messageSlice";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 4,
};

const AdministrarInvestigacionElement = ({
  id = "",
  isNew = false,
  setIsNew = null,
}) => {
  const dispatch = useDispatch();
  const investigations = useSelector(
    ({ investigation }) => investigation.investigations
  );
  const investigation = investigations?.find((item) => item._id == id);

  const [loading, setLoading] = React.useState(false);
  const [canEdit, setCanEdit] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const defaultValues = {
    title: "",
    authors: "",
    date: "",
    link: "",
    type: INVESTIGATION_TYPE[0].value,
  };

  const schema = yup.object().shape({
    title: yup.string().required("Debe ingresar un título"),
    authors: yup.string().required("Debe ingresar el/los autores"),
    date: yup
      .string()
      .required("Debe seleccionar una fecha")
      .test("valid-date", "La fecha ingresada no es válida", function (value) {
        return moment(value, "DD-MM-YYYY", true).isValid();
      }),
    link: yup.string().required("Debe ingresar la URL del artículo"),
    type: yup.string().required("Debe seleccionar un tipo"),
  });

  const {
    control,
    formState,
    handleSubmit,
    setError,
    setValue,
    getValues,
    watch,
  } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;

  function onSubmit() {
    if (id) {
      dispatch(updateInvestigation({ id: id, data: getValues() }))
        .then(() => {
          dispatch(
            showMessage({
              message: "Se ha guardado con éxito",
              autoHideDuration: 4000,
              severety: "success",
            })
          );
          dispatch(getInvestigations());
          setCanEdit(false);
        })
        .catch((error) =>
          dispatch(
            showMessage({
              message: error.message,
              autoHideDuration: 4000,
              severety: "error",
            })
          )
        );
    } else {
      dispatch(createInvestigation(getValues()))
        .then(() => {
          dispatch(
            showMessage({
              message: "Artículo registrado con éxito",
              autoHideDuration: 4000,
              severety: "success",
            })
          );
          dispatch(getInvestigations());
          setIsNew(false);
        })
        .catch((error) =>
          dispatch(
            showMessage({
              message: error.message,
              autoHideDuration: 4000,
              severety: "error",
            })
          )
        );
    }
  }

  const setKeysValues = () => {
    Object.keys(defaultValues).forEach((key) => {
      if (investigation.hasOwnProperty(key)) {
        setValue(key, investigation[key]);
      }
    });
  };

  React.useEffect(() => {
    if (id) setKeysValues();
  }, [id]);

  return (
    <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
      <TableCell>
        <div>
          {canEdit || isNew ? (
            <div className="flex justify-between">
              <Button
                variant="contained"
                color="secondary"
                className=" w-[140px] rounded-lg font-bold"
                disabled={!isValid || loading}
                onClick={onSubmit}
                size="large"
                startIcon={<SaveIcon />}
              >
                {loading ? (
                  <CircularProgress size={27} />
                ) : (
                  <p className="font-bold">Guardar</p>
                )}
              </Button>
              <Button
                variant="contained"
                color="secondary"
                className=" w-[140px] rounded-lg font-bold"
                disabled={loading}
                onClick={() => {
                  if (id) {
                    setKeysValues();
                    setCanEdit(false);
                  } else setIsNew(false);
                }}
                size="large"
              >
                {loading ? (
                  <CircularProgress size={27} />
                ) : (
                  <p className="font-bold">Cancelar</p>
                )}
              </Button>
            </div>
          ) : (
            <div className="flex justify-between">
              <Button
                variant="contained"
                color="secondary"
                className=" w-[140px] rounded-lg font-bold"
                onClick={() => setCanEdit(true)}
                size="large"
                startIcon={<BorderColorIcon />}
              >
                <p className="font-bold">Editar</p>
              </Button>
              <Button
                variant="contained"
                color="secondary"
                className=" w-[140px] rounded-lg font-bold"
                onClick={() => setOpenDelete(true)}
                size="large"
                startIcon={<DeleteIcon />}
              >
                <p className="font-bold">Eliminar</p>
              </Button>
            </div>
          )}
        </div>
      </TableCell>
      <TableCell>
        <Controller
          name="authors"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              disabled={!(canEdit || isNew)}
              className="mb-24"
              label="Autores"
              error={!!errors.authors}
              helperText={errors?.authors?.message}
              variant="outlined"
              required
            />
          )}
        />
      </TableCell>
      <TableCell>
        <Controller
          name="date"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              className="mb-24"
              label="Fecha de publicación"
              disabled={!(canEdit || isNew)}
              placeholder="DD-MM-YYYY"
              error={!!errors.date}
              helperText={errors?.date?.message}
              variant="outlined"
              required
            />
          )}
        />
      </TableCell>
      <TableCell>
        <Controller
          name="title"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              disabled={!(canEdit || isNew)}
              className="mb-24"
              label="Título"
              error={!!errors.title}
              helperText={errors?.title?.message}
              variant="outlined"
              required
            />
          )}
        />
      </TableCell>
      <TableCell>
        <Controller
          name="link"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              disabled={!(canEdit || isNew)}
              className="mb-24"
              label="Link"
              error={!!errors.link}
              helperText={errors?.link?.message}
              variant="outlined"
              required
            />
          )}
        />
      </TableCell>

      <TableCell>
        <Controller
          name="type"
          control={control}
          render={({ field }) => (
            <FormControl>
              <InputLabel>Tipo *</InputLabel>
              <Select
                disabled={!(canEdit || isNew)}
                label="Tipo *"
                {...field}
                className="w-full"
              >
                {INVESTIGATION_TYPE.map((item, index) => (
                  <MenuItem key={index} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
      </TableCell>
      {openDelete && (
        <Modal open={openDelete} onClose={handleCloseDelete}>
          <Box sx={style}>
            ¿Seguro que desea borrar este articulo? Este proceso es
            irreversible.
            <div className="flex justify-end mt-4 gap-6">
              <Button
                variant="contained"
                color="error"
                onClick={() => {
                  dispatch(deleteInvestigation(id))
                    .then((response) => {
                      dispatch(
                        showMessage({
                          message: "El artículo ha sido eliminado con exito",
                          autoHideDuration: 4000,
                          severety: "success",
                        })
                      );
                      dispatch(getInvestigations());
                      setOpenDelete(false);
                    })
                    .catch(() => {
                      dispatch(
                        showMessage({
                          message: "Ha ocurrido un error",
                          autoHideDuration: 4000,
                          severety: "error",
                        })
                      );
                    });
                }}
              >
                Eliminar
              </Button>
              <Button
                variant="outlined"
                color="error"
                onClick={() => {
                  setOpenDelete(false);
                }}
              >
                Cancelar
              </Button>
            </div>
          </Box>
        </Modal>
      )}
    </TableRow>
  );
};

export default AdministrarInvestigacionElement;
