import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchWithToken, fetchWithoutToken } from "../helpers/fetch";

const initialState = {
  investigations: [],
  investigation: {},
};

export const createInvestigation = createAsyncThunk(
  "investigation/create",
  async (data) => {
    let investigation;

    try {
      const response = await fetchWithToken({
        endpoint: `/investigation/register`,
        data: data,
        method: "POST",
      });
      investigation = response.data;
    } catch (error) {
      throw error;
    }

    return investigation;
  }
);

export const getInvestigations = createAsyncThunk(
  "investigations/list",
  async (filters) => {
    let list;

    const filter = JSON.stringify(filters);

    try {
      const response = await fetchWithoutToken({ endpoint: `/investigations` });
      list = response.data;
    } catch (error) {
      list = [];
    }
    return list;
  }
);

export const getInvestigationById = createAsyncThunk(
  "investigation",
  async (id) => {
    let investigation = {};

    try {
      const response = await fetchWithoutToken(`/investigation/${id}`);
      investigation = response.data;
    } catch (error) {
      investigation = {};
    }
    return investigation;
  }
);

export const updateInvestigation = createAsyncThunk(
  "investigation/update",
  async ({ id, data }) => {
    let investigation = {};

    try {
      const response = await fetchWithToken({
        endpoint: `/investigation/update/${id}`,
        data: data,
        method: "PUT",
      });
      investigation = response.data;
      return investigation;
    } catch (error) {
      throw error;
    }
  }
);

export const deleteInvestigation = createAsyncThunk(
  "investigation/delete",
  async (id) => {
    let investigationDeleted = {};

    try {
      const response = await fetchWithToken({
        endpoint: `/investigation/delete/${id}`,
        method: "DELETE",
      });
      investigationDeleted = response.data;
    } catch (error) {
      investigationDeleted = {};
    }
    return investigationDeleted;
  }
);

const investigationSlice = createSlice({
  initialState,
  name: "investigation",
  reducers: {},
  extraReducers: {
    [getInvestigations.fulfilled]: (state, action) => {
      state.investigations = action.payload;
    },
    [getInvestigationById.fulfilled]: (state, action) => {
      state.investigation = action.payload;
    },
  },
});

export default investigationSlice.reducer;
