import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchWithToken, fetchWithoutToken } from "../helpers/fetch";

const initialState = {
  projects: [],
  project: {},
};

export const createProject = createAsyncThunk(
  "project/create",
  async (data) => {
    let project;

    try {
      const response = await fetchWithToken({
        endpoint: `/project/register`,
        data: data,
        method: "POST",
      });
      project = response.data;
    } catch (error) {
      throw error;
    }

    return project;
  }
);

export const getProjects = createAsyncThunk(
  "projects/list",
  async (filters) => {
    let list;

    const filter = JSON.stringify(filters);

    try {
      const response = await fetchWithoutToken({ endpoint: `/projects` });
      list = response.data;
    } catch (error) {
      list = [];
    }
    return list;
  }
);

export const getProjectById = createAsyncThunk("project", async (id) => {
  let project = {};

  try {
    const response = await fetchWithoutToken(`/project/${id}`);
    project = response.data;
  } catch (error) {
    project = {};
  }
  return project;
});

export const updateProject = createAsyncThunk(
  "project/update",
  async ({ id, data }) => {
    let project = {};

    try {
      const response = await fetchWithToken({
        endpoint: `/project/update/${id}`,
        data: data,
        method: "PUT",
      });
      project = response.data;
      return project;
    } catch (error) {
      throw error;
    }
  }
);

export const deleteProject = createAsyncThunk("project/delete", async (id) => {
  let projectDeleted = {};

  try {
    const response = await fetchWithToken({
      endpoint: `/project/delete/${id}`,
      method: "DELETE",
    });
    projectDeleted = response.data;
  } catch (error) {
    projectDeleted = {};
  }
  return projectDeleted;
});

const projectSlice = createSlice({
  initialState,
  name: "project",
  reducers: {},
  extraReducers: {
    [getProjects.fulfilled]: (state, action) => {
      state.projects = action.payload;
    },
    [getProjectById.fulfilled]: (state, action) => {
      state.project = action.payload;
    },
  },
});

export default projectSlice.reducer;
