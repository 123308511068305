import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import _ from "lodash";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { TextField, CircularProgress } from "@mui/material";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { login } from "../redux/slices/authSlice";
import { showMessage } from "../redux/slices/messageSlice";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 4,
};

export default function LoginModal() {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const schema = yup.object().shape({
    dni: yup
      .string()
      .matches(/^[0-9]+$/, "El DNI debe contener solo números")
      .required("Debe ingresar su DNI")
      .test("digits", "El DNI debe ser un número válido", function (value) {
        if (isNaN(value)) {
          return false;
        }
        const stringValue = String(value);
        return stringValue.length === 8;
      }),
    password: yup.string().required("Debe ingresar su contraseña"),
  });

  const defaultValues = {
    dni: "",
    password: "",
  };

  const { control, formState, handleSubmit, setError, setValue } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;

  function onSubmit({ dni, password }) {
    setLoading(true);
    dispatch(login({ dni, password }))
      .then(({ payload }) => {
        localStorage.setItem("jwt_access_token_speas", payload.token);
        localStorage.setItem("data_user_speas", JSON.stringify(payload));
        setOpen(false);
        window.location.reload();
        dispatch(
          showMessage({
            message: "Se ha logeado con éxito",
            autoHideDuration: 4000,
            severety: "success",
          })
        );
      })
      .catch((error) => {
        dispatch(
          showMessage({
            message: "DNI o contraseña incorrectos",
            autoHideDuration: 4000,
            severety: "error",
          })
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        className=" w-full mt-16 rounded-lg font-bold"
        onClick={handleOpen}
      >
        <p className="font-bold">Ingresar</p>
      </Button>
      {open && (
        <Modal open={open} onClose={handleClose}>
          <Box sx={style}>
            <div className="flex flex-col items-center w-full max-w-320 sm:w-320 mx-auto sm:mx-0">
              <form
                name="loginForm"
                noValidate
                className="flex flex-col justify-center w-full"
                onSubmit={handleSubmit(onSubmit)}
              >
                <p className="text-xl mt-4">DNI</p>
                <Controller
                  name="dni"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="mb-24"
                      autoFocus
                      error={!!errors.dni}
                      helperText={errors?.dni?.message}
                      variant="outlined"
                      required
                      fullWidth
                    />
                  )}
                />
                <p className="text-xl mt-4">Contraseña</p>
                <Controller
                  name="password"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="mb-24"
                      type="password"
                      error={!!errors.password}
                      helperText={errors?.password?.message}
                      variant="outlined"
                      required
                      fullWidth
                    />
                  )}
                />

        

                <Button
                  variant="contained"
                  color="secondary"
                  className=" w-full mt-4 rounded-lg font-bold"
                  disabled={_.isEmpty(dirtyFields) || !isValid || loading}
                  type="submit"
                  size="large"
                >
                  {loading ? (
                    <CircularProgress size={27} />
                  ) : (
                    <p className="font-bold">Ingresar</p>
                  )}
                </Button>
              </form>
              <img
                className="w-[360px] mt-8"
                src="/assets/images/logo-light.png"
                alt="logo"
              />
            </div>
          </Box>
        </Modal>
      )}
    </div>
  );
}
