import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import { useDispatch } from "react-redux";
import { getCurrentModal } from "../../redux/slices/modalSlice";
import { getActivityById } from "../../redux/slices/activitySlice";
import { Grid } from "@mui/material";
import ButtonSpeas from "./ButtonSpeas";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 1000,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 4,
  overflowY: "auto",
  height: "95%",
};

const ActivityModal = () => {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [data, setData] = useState();
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const lastShownDate = localStorage.getItem("modalShownDate");
    const currentDate = new Date().toLocaleDateString();

    if (lastShownDate !== currentDate) {
      dispatch(getCurrentModal()).then(({ payload }) => {
        dispatch(getActivityById(payload.id_activity)).then(({ payload }) => {
          setData(payload);
          handleOpen();
          localStorage.setItem("modalShownDate", currentDate);
        });
      });
    }
  }, [dispatch]);

  return (
    <>
      {open && data && (
        <Modal open={open} onClose={handleClose}>
          <Box sx={style}>
            <Grid xs={12} container spacing={2}>
              <Grid item xs={12}>
                <p
                  className={`text-xl lg:text-4xl font-bold px-4 lg:px-20 pt-8 text-center`}
                >
                  {data.title}
                </p>
              </Grid>
              <Grid item xs={12}>
                <div className="w-full h-full flex justify-center items-center">
                  <img
                    className={`max-h-[95%] max-w-[95%] md:max-w-[100%] md:max-h-[90%]`}
                    src={data.image ? data.image : "/assets/images/image.jpg"}
                    alt="Imagen no encontrada"
                  />
                </div>
              </Grid>
              <Grid item xs={12} style={{ display: "flex" }}>
                <div className="w-full h-full flex flex-col justify-center px-8 lg:px-20 pb-8">
                  <p className={`mt-4 lg:text-xl text-justify`}>
                    {data.description}
                  </p>

                  <div className="flex justify-center mt-12 gap-x-12 gap-y-4 flex-wrap">
                    {data.inscription && (
                      <ButtonSpeas
                        onClick={() => window.open(data.inscription, "_blank")}
                      >
                        Inscripciones aquí
                      </ButtonSpeas>
                    )}
                    <ButtonSpeas onClick={() => handleClose()}>
                      Cerrar
                    </ButtonSpeas>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      )}
    </>
  );
};

export default ActivityModal;
