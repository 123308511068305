import { amber, blue, green } from "@mui/material/colors";
import {
  Typography,
  SnackbarContent,
  Snackbar,
  IconButton,
  Icon,
  Alert,
} from "@mui/material";
import clsx from "clsx";
import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hideMessage } from "../../redux/slices/messageSlice";

const variantIcon = {
  success: "check_circle",
  warning: "warning",
  error: "error_outline",
  info: "info",
};

function Notification(props) {
  const dispatch = useDispatch();
  const state = useSelector(({ message }) => message.state);
  const options = useSelector(({ message }) => message.options);

  return (
    <Snackbar
      {...options}
      open={state}
      autoHideDuration={options.autoHideDuration}
      onClose={() => dispatch(hideMessage())}
    >
      <Alert
        onClose={() => dispatch(hideMessage())}
        variant="filled"
        severity={options.severety}
        sx={{ width: "100%" }}
      >
        {options.message}
      </Alert>
    </Snackbar>
  );
}

export default memo(Notification);
