import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchWithToken, fetchWithoutToken } from "../helpers/fetch";

const initialState = {};

export const login = createAsyncThunk("login", async ({ dni, password }) => {
  try {
    const response = await fetchWithoutToken({
      endpoint: `/login`,
      data: { dni, password },
      method: "POST",
    });
    const { photo, token, name, lastname } = response.data;
    return { photo, token, name, lastname };
  } catch (error) {
    return null;
  }
});

const authSlice = createSlice({
  initialState,
  name: "auth",
  reducers: {},
  extraReducers: {},
});

export default authSlice.reducer;
