import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchWithToken, fetchWithoutToken } from "../helpers/fetch";

const initialState = {
  modals: [],
  modal: {},
};

export const createModal = createAsyncThunk("modal/create", async (data) => {
  let modal;

  try {
    const response = await fetchWithoutToken({
      endpoint: `/modal/register`,
      data: data,
      method: "POST",
    });
    modal = response.data;
  } catch (error) {
    throw error;
  }

  return modal;
});

export const getModals = createAsyncThunk("modals/list", async () => {
  let list;

  try {
    const response = await fetchWithoutToken({ endpoint: `/modals` });
    list = response.data;
  } catch (error) {
    list = [];
  }
  return list;
});

export const getModalById = createAsyncThunk("modal", async (id) => {
  let modal = {};

  try {
    const response = await fetchWithoutToken(`/modal/${id}`);
    modal = response.data;
  } catch (error) {
    modal = {};
  }
  return modal;
});

export const getCurrentModal = createAsyncThunk("modal/current", async () => {
  let modal;

  try {
    const response = await fetchWithoutToken({ endpoint: `/modals` });
    modal = response?.data[0];
  } catch (error) {
    modal = {};
  }
  return modal;
});

export const updateModal = createAsyncThunk(
  "modal/update",
  async ({ id, data }) => {
    let modal = {};

    try {
      const response = await fetchWithToken({
        endpoint: `/modal/update/${id}`,
        data: data,
        method: "PUT",
      });
      modal = response.data;
      return modal;
    } catch (error) {
      throw error;
    }
  }
);

const modalSlice = createSlice({
  initialState,
  name: "modal",
  reducers: {},
  extraReducers: {
    [getModals.fulfilled]: (state, action) => {
      state.modals = action.payload;
    },
    [getModalById.fulfilled]: (state, action) => {
      state.modal = action.payload;
    },
    [getCurrentModal.fulfilled]: (state, action) => {
      state.modal = action.payload;
    },
  },
});

export default modalSlice.reducer;
